@import "src/styles/variables";

.treatment-appointments {
    .ant-collapse-content-box {
        padding-top: 0px !important;
        .ant-checkbox-wrapper {
            display: block;
            margin-left: 0px !important;
            margin-bottom: 8px;
        }
    }
}