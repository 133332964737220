@import "src/styles/variables";
@import "src/styles/themes";

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  padding: 1rem 2rem;
  padding-block: 5px;
  z-index: 3;

  &__right{
    margin-left: auto;
  }

  .app-header__practice {
    min-width: 150px;
    .ant-select-selector {
      border-radius: 8px !important;
      height: 40px;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @include themed() {
    display: setStyle("header-display");
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector {
  border-color: none !important;
}

.app-header__logo {
}

.app-header__menu {
}

.app-header__logo-wrapper {
  border-right: 1px solid $primary-border;
  display: flex !important;
  align-items: center;
  gap: 20px;
}

.app-header__title {
  padding-left: 1rem;
}

.ant-btn.secondary-btn.app-header__notification-btn {
  position: relative;
  padding: 5px 10px;
  font-size: 1rem;
  border-radius: 4px;
  height: 35px;
  width: 35px;
}

.ant-btn.secondary-btn.app-header__calender-btn,
.ant-btn.secondary-btn.app-header__clock-btn {
  position: relative;
  padding: 5px 10px;
  font-size: 1rem;
  border-radius: 4px;
  margin-right: 8px;
  .app-header__notification-count {
    display: none;
  }
}

.app-header__notification-count {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: $danger-color;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  font-size: 10px;
}
