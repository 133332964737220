@import "src/styles/variables";
.end-contract-modal,
.complete-contract-modal {
  .ant-modal-confirm-btns {
    width: 100% !important;
    text-align: center !important;

    button {
      width: 67px;
      height: 38px;
      background-color: #ffff;
      border: 1px solid #d9d9d9;
      letter-spacing: 0px;
      color: #4f4f4f;
      font-weight: 300;
      opacity: 1;
      padding: 0;
      vertical-align: middle;
      span {
      }
    }
  }
  .ant-modal-confirm-body {
    display: flex;
    justify-content: center;
    svg {
      display: none;
    }
    .ant-modal-confirm-content {
      text-align: center;
    }
  }
  .ant-modal-content {
    top: 7rem;
    color: $primary-black;
    width: 420px;
    height: 175px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 25px #66666629;
    border-radius: 12px;
    opacity: 1;
  }
}
.complete-contract__noteAdded {
  @extend.complete-contract-modal;
  .ant-modal-content {
    width: 508px !important;
    height: 233px !important;
  }
  .note {
    text-align: left !important;
    position: absolute;
    top: 62%;
    left: 3%;
  }
}

.list-contract {
  .list-contract__icons {
    display: flex;
    visibility: hidden;
  }
  .ant-table-container {
    .ant-table-row:hover {
      .list-contract__icons {
        visibility: visible;
        transform: translateY(-5px);
        transition: 0.3s ease-in;
      }
    }
  }
}
