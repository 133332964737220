@import "src/styles/variables";

.set-viewer {
  height: 100%;
  position: relative;

  .babylon-scene, #babylon-scene__canvas {
    height: 100%;
  }
}

.ant-popover.set-viewer__popover {
  .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    background-color: $card-bg--dark;
    box-shadow: $primary-shadow;
    width: 350px;
    color: $primary-white;
  }
}

#loader-container {
  background: black;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  #loader {
    color: white;
    font-size: 20px;
  }
}
