@import "src/styles/variables";

.practice-details {
    .patient-detail__wrapper {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 20px 50px #6666660D;
        padding: 1.75rem;
        margin-top: 2rem;
        .dark-text {
            margin: auto 0 4px;
            color: $secondary-black;
            font-size: 12px;
        }

        .bold-text {
            font-weight: bold;
        }
    }
}