@import "src/styles/variables";
.agreement {
  max-height: 60vh;
  overflow-y: scroll;

  &__navigator {
    position: absolute;
    inset-block-start: calc(2rem + 10px);
    inset-inline-start: 15%;
    background-color: $primary-white;
  }

  .agreement__signature {
    display: flex;
    
    img {
      margin-top: 10px;
      width: 300px;
      border: 0.2px solid black;
      padding: 1rem;
      border-radius: 12px;
    }
  }
  .ant-upload {
    margin-top: 30px;
    cursor: pointer;
    background-color: $light-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    border-radius: 8px;
  }
}
.ant-carousel .slick-list .slick-slide{
  pointer-events: auto !important;
}