@import "src/styles/variables";

.contract-custom-form {
}
.contract-card-details__success-modal-content {
  margin: 4rem;
  box-shadow: $primary-shadow;
  padding: 4rem;
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  img {
    width: 50%;
    display: block;
    margin: 1rem auto 3rem auto;
  }
}
