@import "src/styles/variables";

.conflict-management {

    .light-color {
        color: $very-light-grey;
    }
	.conflict__wrapper {
		background-color: white;
		padding: 1.75rem;
		box-shadow: 0px 20px 50px #6666660d;
		border-radius: 8px;
	}
	.conflict-controller {
        margin-top: 2rem;
		display: flex;
		justify-content: flex-end;
	}
}
