@import "src/styles/variables";

.ongoing-appointments__container {
    .payment-status {
        color: $primary-color;
    }

    .interaction-btn {
        width: 100%;
        padding: 10px !important;
    }
}