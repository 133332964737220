.month-calender__container {
  .ant-picker-calendar-header {
    display: none;
  }
  .month-calender {
    background-color: white;
    padding: 10px 3rem;
    box-shadow: 0px 20px 50px #66666614;
    border-radius: 4px;
    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
      .ant-picker-cell-inner.ant-picker-calendar-date {
        background: transparent !important;
        .ant-picker-calendar-date-value {
          color: black;
        }
      }
    }
    .ant-picker-cell-inner.ant-picker-calendar-date {
      position: relative;
      .ant-picker-calendar-date-value {
        position: absolute;
        top: 30%;
        left: 40%;
      }
      .ant-picker-calendar-date-content {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        p {
          background-color: pink;
          margin-bottom: 10px;
          padding: 2px 8px;
          border-radius: 10px;
        }
        .holiday-wrapper {
          background-color: chartreuse;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .ant-picker-cell {
    border: 1px solid #f9f9f9;
  }
  .ant-picker-content {
    tr {
      th {
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .calender-cell-holiday {
    background-color: #cffdcb;
  }

  .calender-cell-today {
    background-color: #00ae89;
  }

  .calender-cell__container {
    position: relative;
    height: 70px;
    width: 100%;
    .date {
      position: absolute;
      left: 45%;
      top: 40%;
    }
    .date {
      font-size: 12px;
    }
    .appointment-available {
      position: absolute;

      left: 3%;
      top: 5%;
      background-color: #a5dcac;
      padding: 3px 8px;
      border-radius: 8px;
      font-size: 8px;
      width: 90%;
      text-align: center;
    }
    .appointments__wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .appointments {
        background-color: pink;

        padding: 3px 8px;
        border-radius: 8px;
        font-size: 8px;
        width: 90%;
        text-align: center;
      }
    }
  }
  .holiday-menu-item {
    color: black;
    font-weight: normal;
    background-color: white;
    box-shadow: 0px 6px 12px #66666640;
    padding: 0.4rem 0.8rem;
    border-radius: 12px;
    z-index: 9999;
  }
  .react-contextmenu {
    z-index: 2 !important;
  }
}
.ant-popover-content {
  .ant-popover-inner {
    border-radius: 12px;
    box-shadow: 0px 6px 12px #66666640;
  }
}
