@import "src/styles/variables";

.detailed-onboarding-payment-details {

}

.detailed-onboarding-payment-details__payment-form {
  padding: 2rem;
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

.detailed-onboarding-payment-details__subscription-details {
  color: $primary-black;
  box-shadow: $secondary-shadow;
  padding: 1rem;
  border-radius: 8px;
  ul > li {
    margin-bottom: 0.8rem;
  }
}

.detailed-onboarding-payment-details__price {
  background-color: $primary-color;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;

}

.selected-subscription {
  .detail {
    text-align: right;
  }
}

.saving {
  border-radius: 24px;
  padding: 4px 16px;
  background-color: rgba($color: $primary-color, $alpha: 0.2);
  display: inline;
}

.detailed-onboarding-payment-details__statistics {
  padding-left: 20px;

}

.detailed-onboarding-payment-details__add-ons {
  ul {
    margin-top: 10px;
    padding-left: 15px;
  }
}

.detailed-onboarding-payment-details__upgrade-wrapper.ant-col {
  position: relative;
}

.ant-btn.detailed-onboarding-payment-details__upgrade-btn {
  position: absolute;
  bottom: 0;
  right: 1rem;
  color: $secondary-color;
  text-decoration: underline;
  &:hover, &:focus, &:active {
    color: $secondary-color;
  }
}
