@import "src/styles/variables";

.work-item {
  background-color: #f7f7f7;
  padding: 1rem 1.5rem;
  border-radius: 6px;
  position: relative;
  margin-bottom: 0.5rem;

  .view-content {
    display: flex;
  }
  .img-wrapper {
    margin-right: 12px;
    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .content {
    p {
      margin: 0px;
      font-size: 16px;
      font-weight: lighter !important;
    }
    .name {
      color: $secondary-color;
    }
    .message {
      color: $primary-black;
    }
  }

  .view-details__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    button {
      padding: 6px 24px;
      border-radius: 4px;
    }
  }
}
