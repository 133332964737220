@import "src/styles/variables";

.register-basic-details-form {
  .ant-input-group-addon {
    width: 100px;
    padding: 10px;
  }

  .input-field.ant-input-group-wrapper {
    .dropdown-field {
      margin: 0;
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0;
      }
    }
  }
}
