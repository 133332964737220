@import "src/styles/variables";
@import "src/styles/helpers";

.interaction-object-actions {
}

.interaction-object-actions__header {
  padding: 12px;
  background-color: $body-bg--dark;
  .ant-btn.ant-btn-icon-only {
    background-color: $card-bg--dark;
    border-radius: 4px;
  }
}

.interaction-object-actions__body {
  padding: 12px;
}

.ant-modal.interaction-object-actions__object-note-modal {
  position: relative;
  z-index: 9999;
  .ant-modal-body{
    @extend .p-0;
  }
}
