@import "src/styles/variables";

.procedure-viewer {
  height: 100%;

  .babylon-scene, #babylon-scene__canvas{
    height: 100%;
  }
}

#procedure-viewer__canvas {
  height: 100%;
  width: 100%;
  &:focus {
    outline: none;
  }
}

.ant-popover.procedure-viewer__popover {
  .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    background-color: $card-bg--dark;
    box-shadow: $primary-shadow;
    width: 350px;
    color: $primary-white;
  }
}
