@import "src/styles/variables";

.attachments {
    .list__attachments {
        padding: 0px 24px;
        margin-bottom: 12px;
    }

    a{ 
        text-decoration: underline;
        color: #2543E6;
    }
}