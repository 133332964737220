@import "src/styles/variables";

.custom-form-response-form {

  .custom-form-response-form__field,
  .custom-form-response-form__section {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: $primary-shadow;
    margin-bottom: 25px;
    padding: 2rem;
  }
}