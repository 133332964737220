@import "src/styles/variables";

.mobile-number-field {
  .ant-input-group-addon {
    width: 75px;

    padding: 0;
    .ant-select {
      margin-top: -7px !important;
      top: 7px;
    }
    .dropdown-field {
      margin: 0;
    }
  }
}
