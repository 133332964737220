@import "src/styles/variables";

.contract-terms-and-conditions {
  position: relative;
  
  .ant-row{
    margin-bottom: 15px;;
    .title {
      margin-bottom: 0;
    }
  }

  canvas {
    width: 100%;
    height: 150px;
    border: 0.5px solid $primary-border;
    border-radius: 8px;
    box-shadow: 0px 5px 25px #66666629;
  }
  .ant-carousel {
    .slick-active {
      // display: none;
    }
  }
  .term-template__options {
    .ant-checkbox-input {
      padding: 1rem;
    }
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
    }
    .ant-checkbox-group-item {
      margin-bottom: 15px;
    }
  }
  .arrow {
    width: 25px;
    cursor: pointer;
  }
  .agreement__details {
    background-color: #58585829;
    padding: 1rem;
    border-radius: 8px;
  }
}
.contract-terms-and-conditions__terms {
  .ant-table {
    .ant-table-cell {
      font-weight: bold;
      color: $primary-black;
      background: #fafafa 0% 0% no-repeat padding-box;
      margin-bottom: 1rem;
    }
  }
}
.term-template__names {
  width: 350px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;

  background-color: $table-bg;
}
