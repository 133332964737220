@import "src/styles/variables";

.treatment-summary-notes {
  .treatment-summary-notes__set-item {
    border: none;
    > .ant-collapse-item,
    > .ant-collapse-item:last-child {
      > .ant-collapse-header {
        border: none;
        background-color: #ffffff;
        box-shadow: $primary-shadow;
        border-radius: 8px;
        padding: 15px;
      }
      .ant-collapse-content {
        margin-top: 10px;
        border: none;
        background-color: $body-bg;
        border-radius: 8px;
        .ant-collapse-content-box {
          padding: 0 0 0 15px;
        }
      }
    }
  }
}

.treatment-summary-notes__link-item,
.treatment-summary-notes__set-step-item {
  border: none;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
}
