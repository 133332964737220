@import "src/styles/variables";

.detailed-onboarding-wrapper {
  color: #ffffff;
  font-weight: bold;
}
.register-wrapper {
}

.detailed-onboarding-wrapper__background-wrapper {
  background-image: linear-gradient(180deg, $secondary-color, $primary-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  height: 100vh;
  .detailed-onboarding-wrapper__background-content {
    text-align: center;
    h3 {
      margin-top: 7rem;
      color: #ffffff;
    }
  }
}

.detailed-onboarding-wrapper__content-wrapper {
  padding: 2rem 4rem;
  margin-left: 29%;
}

.detailed-onboarding-wrapper__header {
  position: fixed;
  top: 0;
  left: 30%;
  width: 70%;
  height: 6rem;
  padding: 2rem 4rem;
  background-color: $body-bg;
  z-index: 1;

  h4 {
    display: inline-block;
    vertical-align: middle;
  }
}

.detailed-onboarding-wrapper__route-wrapper {
  margin-top: 5rem;
}

.detailed-onboarding-wrapper__logo {
  margin-top: 3rem;
  width: 40%;
}

.detailed-onboarding-wrapper__progress-wrapper {
  padding: 1rem;
  background-color: $progress-bg;
  border-radius: 8px;
  width: 80%;
  margin: 2rem auto 0 auto;
}

.detailed-onboarding-wrapper__progress-text {
  background-color: $progress-text-bg;
  border-radius: 8px;
  padding: 10px;
  font-weight: bold;
  color: #ffffff;
}

.detailed-onboarding-wrapper__stepper {
  margin-top: 6rem;

  > div > .detailed-onboarding-wrapper__step-item {
    background-color: transparent;
    border: 1px solid #ffffff;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    &.active {
      background-color: #ffffff;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.detailed-onboarding-wrapper__content-wrapper {
  margin-left: 10%;
  width: 90%;
  margin-top: -8rem;
}
