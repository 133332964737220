@import "src/styles/variables";

.treatment-template-form {
  .treatment-template-form__wrapper {
    background-color: white;
    padding: 2.5rem;
    box-shadow: 0px 20px 50px #6666660d;
    border-radius: 8px;
  }

  .treatment-template-form__controller {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
    .ant-btn-ghost {
      margin-right: 12px;
    }
  }

  //   input,
  //   .dropdown-field {
  //     width: 50%;
  //   }
}
