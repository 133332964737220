@import "src/styles/variables";

.user-responsibility {
  background-color: white;
  box-shadow: 0px 2px 20px #5858580d;
  border-radius: 8px;
  padding: 1.5rem 2.5rem;
  margin-bottom: 1rem;

  .head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    .title {
      color: $secondary-color;
      display: inline-block;
      font-size: 16px;
    }

    .description {
      // color: $label-color;
      font-weight: lighter;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    width: 100%;
    background-color: #FAFAFA;
    transition: height 0.5s;
    border-radius: 4px;
    padding: 2.5rem;

    .card {
        cursor: pointer;
        background: #F2F2F2;
        font-weight: lighter;
        padding: 0.5rem;
        padding-left: 1.75rem;
        margin: 1rem 0px;
        border-radius: 2px;

        &.card-selected {
            background: $primary-color;
            color: white;
        }
    }
     .ant-col-4 {
        text-align: center;
    }
    .ant-col-8 {
        padding-left: 24px;
    }
  }

  .content-open {
    height: 100% !important;
  }

  .content-close {
    height: 0% !important;
  }
}
