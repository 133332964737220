@import "src/styles/variables";

.check-list {
	.check-list__progress__container {
		background-color: white;
		box-shadow: 0px 20px 50px #6666660d;
		border-radius: 8px;
		padding: 1rem;
		.check-list__progress__wrapper {
			background-color: rgba($color: $primary-color, $alpha: 0.1);
			border-radius: 8px;
			padding: 1rem;
			display: flex;
			.check-list__progress__percentage {
				background-color: $primary-color;
				padding: 0.6rem 0.8rem;
				border-radius: 8px;
				font-weight: bold;
				color: white;
			}
			.ant-progress {
				display: flex;
				align-items: center;
				.ant-progress-inner {
					background-color: white;
					.ant-progress-bg {
						background-color: $primary-color;
						height: 12px;
					}
				}
			}
		}
	}

	.list-items__wrapper {
		background-color: white;
		padding: 1.5rem 0px 1.5rem 2.5rem;
		box-shadow: 0px 20px 50px #6666660d;
		border-radius: 8px;
	}
}
