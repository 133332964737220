@import "src/styles/variables";

.x-ray-notes {
    .title {
        display: flex;
        justify-content: space-between;
        p {
            margin: auto 0;
        }
        
        button {
            padding: 4px 16px;
            border-radius: 4px; 
            font-weight: normal;           
        }
    }
}