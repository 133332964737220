@import "src/styles/variables";

.patient-form {
}

.patient-form__basic-details-wrapper,
.patient-form__responsible-person-wrapper,
.patient-form__custom-form-wrapper {
  padding: 3rem;
  box-shadow: $primary-shadow;
  background-color: #ffffff;
  border-radius: 8px;
  h4 {
    padding-bottom: 15px;
    border-bottom: 1px solid $primary-border;
    margin-bottom: 2rem;
  }
}

.patient-form__basic-details-wrapper,
.patient-form__responsible-person-wrapper {
  margin-bottom: 2rem;
}

.patient-form__submit-wrapper {
  margin-top: 1rem;
}

.patient-form__responsible-person-request-wrapper {
  margin: 20px 0;
}
