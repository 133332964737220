@import "variables";
$themes: (
        default: (
                app-wrapper-margin: $app-wrapper-margin,
                app-wrapper-width: $app-wrapper-width,
                body-bg: $body-bg,
                sidebar-display: $sidebar-display,
                header-display: $header-display,
                label-color: $secondary-black,
                text-color: $primary-black,
                input-bg: $input-bg,
                input-text-color: $primary-black,
                default-btn-color: $primary-white,
        ),
        dark: (
                app-wrapper-margin: $app-wrapper-margin--dark,
                app-wrapper-width: $app-wrapper-width--dark,
                body-bg: $body-bg--dark,
                sidebar-display: $sidebar-display--dark,
                header-display: $header-display--dark,
                label-color: $primary-text--dark,
                input-bg: $input-bg--dark,
                input-text-color: $primary-text--dark,
                text-color: $primary-text--dark,
                default-btn-color: $input-bg--dark,
        ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function setStyle($key) {
  @return map-get($theme-map, $key);
}
