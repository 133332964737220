.start-interaction-form__container {
    .start-interaction-form__wrapper {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 20px 50px #6666660D;
        border-radius: 8px;
        padding: 2.5rem;
        p {
            color: #8e8e8e;
            margin: auto 0;
        }
        .ant-radio-group.ant-radio-group-outline {
            margin-top: 8px;
            margin-bottom: 1.2rem;
            span {
                color: #8e8e8e;
            }
        }
    }

    .start-interaction-form__control {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    }
}
