@import "src/styles/variables";

.corporate-object-list {
}

.corporate-object-list__color {
  display: block;
  width: 150px;
  height: 30px;
  border: 1px solid $primary-border;
  border-radius: 4px;
}
