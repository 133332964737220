@import "src/styles/variables";

.treatment-template-notes {
  overflow-y: auto;
  box-shadow: 0px 3px 12px #00000014;
  border-radius: 2px;
  background-color: $input-bg--dark;
  padding: 1rem;
  height: 80vh;
  .btn-small {
    padding: 8px 12px !important;
    border-radius: 2px !important;

    img {
      height: 14px;
    }
  }
  .btn-add-statement {
    background-color: $primary-color !important;
    padding: 3px 5px !important;
  }

  .full-width {
    width: 100% !important;
    font-weight: normal;
  }

  .appointments__container {
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.statement-note__collapse {
  // .ant-collapse {
  //   background: #4f4f4f 0% 0% no-repeat padding-box;
  //   border-radius: 2px;
  //   border: none;
  // }
  .ant-collapse {
    border-radius: 2px;
    .ant-collapse-header {
      background: #4f4f4f 0% 0% no-repeat padding-box;

      color: white !important;
    }
    .ant-collapse-content-box {
      background-color: white;
    }
  }
}

.treatment-step-information__wrapper {
  .input__wrapper {
    background-color: white;
    padding: 2.5rem;
    box-shadow: 0px 20px 50px #6666660d;
    border-radius: 8px;
  }

  .controller__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem;
    .ant-btn.ant-btn-text {
      margin-right: 0.75rem;
    }
  }
}
.bout-title {
  color: $primary-white;
}
