@import "src/styles/variables";

.schedule-form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: $primary-shadow;
}

.schedule-form__availabiltiy {
  margin: 1rem 0;
  span,
  button {
    display: inline-block;
    vertical-align: middle;
  }
}

.schedule-form__availability__slots{
  .time-picker-field{
    margin-bottom:0;
  }
}