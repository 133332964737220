.un-allocated-slot {
    color: #000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &__popover {
        color: #4F4F4F;

        .ant-popover-inner {
            border-radius: 4px;
            cursor: pointer;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__profiles {
            display: flex;
            gap: 4px;

            &__doctor {
                color: white;
                display: inline-block;
                padding: 2px 8px 3px 7px;
                background-color: #9C5ED1;
                border-radius: 4px;
                font-size: 10px;
                word-wrap: normal;

                &::after {
                    content: "-D";
                }
            }

            &__assistant {
                color: white;
                display: inline-block;
                background-color: #5E6FD1;
                border-radius: 4px;
                font-size: 10px;
                padding: 2px 8px 3px 7px;


                &::after {
                    content: "-A";
                }
            }
        }
    }
}