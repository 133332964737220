@import "src/styles/variables";

.subscription {
    padding: 1.25rem 2.75rem;
    background: white;
    border-radius: 8px;
    border: $primary-border;
  p {
    font-size: 18px;
    color: $primary-black;
    margin: 0px 0px 4px;
  }
  h1 {
    color: $primary-color;
    display: flex;
    align-items: center;
  }

  .trial-period {
    font-size: 12px;
    background-color: #00AE8927;
    color: $primary-black;
    padding: 6px 14px;
    border-radius: 20px;
    margin-left: 12px;
    font-weight: lighter !important;
  }

  .benefits {
    padding-left: 0px;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      .anticon.anticon-check {
        font-size: 8px;
        background: $secondary-color;
        color: white;
        padding: 4px;
        border-radius: 100%;
        margin-right: 1rem;
      }
    }
  }
  .feature {
    color: $dark-blue;
    font-size: 14px;
    margin-top: 1.25rem;
  }
  .features-list {
    padding-left: 18px;
    li {
        color: rgba($color: $primary-black, $alpha: 0.6);
        margin-bottom: 0.25rem;
    }
  }
}
