@import "src/styles/variables";

.patient-basic-details {
}

.patient-basic-details__img-wrapper {
  text-align: center;
}

.patient-basic-details__profile-pic {
  border-radius: 50em;
  width: 50px;
  cursor: pointer;
  &.large {
    width: 40%;
  }
}

.patient-basic-details__popover-body {
  .patient-basic-details__img-wrapper {
    text-align: center;
    margin: 1rem 0;
    .patient-basic-details__profile-pic {
      border-radius: 50em;
      width: 40%;
      cursor: pointer;
    }
  }
}

.ant-popover.patient-basic-details__popover {
  > .ant-popover-content {
    > .ant-popover-arrow {
      border-top-color: $popover-default-bg;
      border-left-color: $popover-default-bg;
    }
  }

  .ant-popover-inner-content {
    background-color: $popover-default-bg;
    width: 400px;
  }
}

.patient-basic-details__popover-body {
  background-color: $primary-white;
  padding: 1rem;
  box-shadow: $primary-shadow;
  border-radius: 4px;
}

.patient-basic-details__field {
  .patient-basic-details__label {
    color: $secondary-black;
    margin-bottom: 5px;
  }
  .patient-basic-details__value {
    font-family: DMSansBold, sans-serif;
    font-size: 15px;
    word-wrap: break-word;
  }
}
