@import "src/styles/variables";

.signature-input {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__field {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__title {
            color: $secondary-black;
        }

        canvas {
            border: 0.2px solid $primary-black;
            padding: 1rem;
            border-radius: 12px;
            height: 150px;
        }
    }

    &__submit {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
}