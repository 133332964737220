@import "src/styles/variables";

.practices {
  .practice__add {
    display: flex;
    justify-content: flex-end;
  }

  .ant-table-cell {
    .practice__edit-btn {
      padding: 4px 10px;
    }
  }
}
.practice__switch {
  width: 20px;
}
