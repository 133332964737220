@import "src/styles/variables";

.radio-input-field {
  margin-bottom: 1.5rem;

  .radio-input-field__title {
    color: $secondary-black;
    margin-bottom: 10px;
  }
}
