.statement-template__container {
	.statement-template__wrapper {
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 20px 50px #6666660d;
		border-radius: 8px;
		padding: 2.5rem;

        .ant-input.ant-input-disabled.input-field {
            color: black;
        }
	}
    .statement-template__controller{
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        .ant-btn.ant-btn-primary {
            margin-left: 10px;
        }
    }
}
