@import "src/styles/variables";

.patient-form-response-wrapper__detail-modal {
  .ant-modal-content {
    width: 800px;
  }
}

.contract-payment-schedule {
  .ant-picker {
    margin-top: 5px;
  }
  .contract-payment-schedule__insurances {
    .insurance-name {
      max-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* UI Properties */
      background: #f2fffc 0% 0% no-repeat padding-box;
      border: 0.5px solid #00ae89;
      border-radius: 8px;
      opacity: 1;
      color: #00ae89;
    }
  }
  .contract-payment-schedule__add-btn {
    margin-top: 12px;
  }
}
.current-payment-schedule {
  width: 100%;
  height: 214px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 1rem 3em;
  p {
    font-size: 16px;
    font-weight: bold;
  }
  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    &::after {
      top: 45%;
      left: 27%;
      width: 5px;
      height: 13px;
    }
  }
}
.contract-payment-schedule__deposit-discount {
  padding: 0 !important;
  p {
    font-size: 16px;
  }
}
.contract-payment-schedule__self-payment,
.contract-payment-schedule__insurances,
.contract-payment-schedule__deposit-discount {
  .input-field-wrapper .hint {
    color: #e6821e;
    font-style: italic;
  }
  padding: 2rem;
  background-color: $table-bg;
  border-radius: 8px;
  margin-bottom: 1rem;
  .checkbox__container {
    label,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      &::after {
        top: 45%;
        left: 27%;
        width: 5px;
        height: 13px;
      }
    }
    span {
      font-family: DMSansBold, sans-serif;
      font-size: 16px;
    }
  }
}

.contract-payment-schedule__insurances-provider-table {
  .ant-col {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 50px #6666660d;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
    margin-bottom: 15px;
  }
}
.contract-payment-schedule__insurances-provider-title {
  .ant-col {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
  }
}

.contract-payment-schedule__deposit-discount {
  background-color: transparent !important;

  .ant-col {
    padding: 2rem !important;
    background: $table-bg;
    // box-shadow: 0px 6px 25px #58585829;
    border-radius: 10px;
    opacity: 1;
  }
}
.contract-cost__warning {
  .ant-modal-content {
    height: 230px !important;
  }

  .contract-cost__content {
    text-align: center;
  }

  .ant-modal-content {
    border-radius: 18px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 50px #6666660d;
    width: 450px;
    height: 200px;
    align-items: center;
    .ant-btn {
      padding: 6px 30px;
      margin-right: 20px;
    }
  }

  img {
    width: 30px;
  }
}

.contract-payment-schedule__deposit {
  margin: 0 !important;
}
.discount-wrapper {
  width: 150%;
  display: flex;

  .ant-input {
    width: 90%;
  }
}

.contract-payment-schedule__form {
  .select-service__overview__cost {
    gap: 10px;
  }
}
