@import "src/styles/variables";

.responsible-person-fields {
  .responsible-person-fields__link-text {
    color: $secondary-black;
    font-size: 14px;
    margin-right: 10px;
  }
}

.responsible-person-fields__search-responsible-person.ant-select-show-search.ant-select-single {
  .ant-select-selector {
    text-align: left;
    box-shadow: $primary-shadow;
    height: auto;
    border-radius: 8px;
    padding: 5px 20px;
    .ant-select-selection-search-input {
      padding: 8px;
      border-radius: 8px;
      height: auto;
    }
  }
}
