@import "src/styles/variables";

.contract-detail-services {
  .list-contract__icons {
    display: flex;
    visibility: hidden;
    align-items: center;
  }

  .contract-detail__btn-icon {
    width: 30px;
    margin-top: 3px;
  }

  .ant-table-container {
    .ant-table-row:hover {
      .list-contract__icons {
        visibility: visible;
        transform: translateY(-5px);
        transition: 0.3s ease-in;
      }
    }
  }

  &__service-table {
    &__title {
      color: $secondary-black;
    }

    &__val {
      background-color: #F8F8F8;
      border-radius: 8px;
      width: 100%;
      padding: 32px 20px;
      margin-top: 10px;
    }
  }
}

.contract-detail-services__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.contract-detail-services__value {
  font-family: DMSansBold, sans-serif;
  font-size: 16px;
}

.contract-detail-services__fields {
  margin-bottom: 1.5rem;
}