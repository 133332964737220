@import "src/styles/variables";

.set-form {
}

.set-form__form-body {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: $primary-shadow;
}

.set-form__submit-wrapper {
  margin-top: 1rem;
}
