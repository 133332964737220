@import "src/styles/variables";

.treatment-summary {
  padding: 1rem 3rem;
  .interaction-summary__controller {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }

  .ant-checkbox-wrapper {
    display: block;
  }
  .check-list__warning-note {
    .ant-checkbox {
      visibility: hidden;
    }
    span {
      color: $danger-color;
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }

  &__tab {
    .ant-tabs-nav {
      padding: 1rem;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab-active {
            background-color: $secondary-color;
            .ant-tabs-tab-btn {
              color: $light-white;
            }
          }
        }
      }
    }
  }
}
