@import "src/styles/variables";

.interaction-viewer {
  margin-top: 1rem;
}

.ant-popover.interaction-viewer__popover {
  .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    background-color: $card-bg--dark;
    box-shadow: $primary-shadow;
    width: 350px;
    color: $primary-white;
    padding: 0;
  }
}
