.payment-filter-form {
  .payment-filter-form__container {
    padding: 1rem 3rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 50px #6666660d;
    border-radius: 8px;
    opacity: 1;
    // .radio-input-field {
    //   .ant-radio-wrapper {
    //     margin-right: 40px !important;
    //     .ant-radio-inner {
    //       height: 20px;
    //       width: 20px;
    //     }
    //   }
    // }
  }
  .payment-filter-form__btn {
    button {
      position: relative;
      top: 90% !important;
    }
    height: 75px;
  }
}
