@import "src/styles/variables";

.list-item {
	.check-list {
		display: flex;
		.content {
            margin-left: 8px;
			.text {
				color: $very-light-grey;
				font-size: 15px;
			}

			.link {
				color: $link-color;
				cursor: pointer;
				font-size: 12px;
				text-decoration: underline;
			}
		}
	}

	p {
		margin: auto 0 4px;
	}
}
