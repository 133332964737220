@import "src/styles/variables";

.patient-documents {
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  .file-detail__file-icon{
    width:40px;
  }
  &__viewer {
        width:500px;
    .ant-modal-content{
        max-height: 600px;
        max-width: 600px;
       .image-container {
   img{
      max-height: 400px;
        max-width: 400px;
   }
    
  }
   
  
   
    }

  }
}

.patient-documents__document-item {
  background-color: $light-grey;
  padding: 1rem;
  border-radius: 8px;
  word-break: break-all;
  height: 100%;
  width: 100%;

  cursor: pointer;
  .patient-documents__icon {

 
    text-align: center;
    svg {
      font-size: 2rem;
    }
  }
}
