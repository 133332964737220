@import "src/styles/variables";

.practice-form {
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  .ant-input-group-addon {
    width: 75px;
    padding: 0;
    .dropdown-field {
      margin: 0;
    }
  }
  .ant-col {
    .ant-switch-checked {
      background-color: #11409a;
    }
    .date-picker-field {
      .ant-picker {
        width: 100px;
      }
      .ant-picker-input > input {
        font-size: 12px;
      }
    }

    .date-picker-field .ant-picker {
      margin-top: 15px;
      padding: 6px !important;
    }
  }
  .practice-form__day-label {
    margin-top: 22px !important;
  }
}
