@import "src/styles/variables";

.detailed-onboarding-success {
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  padding: 4rem;
  text-align: center;
  margin-top: -5rem;
}

.detailed-onboarding-success__img {
  display: block;
  margin: 0 auto 2rem auto;
}
