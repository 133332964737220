@import "src/styles/variables";

.service-list {
}

.service-list__service-status {
  &.active {
    color: $success-color;
  }

  &.inactive {
    color: $danger-color;
  }
}

.service-list__add-wrapper {
  margin: -4rem 0 1rem 0;
  text-align: right;
}

.default-term-template-wrapper{
  margin-top: -0.5rem;
}

.term-template-tabs {
  &.ant-tabs-top > .ant-tabs-nav{
    margin-bottom: 0;
  }
}