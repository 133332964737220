.show-work-item__container {
	.show-work-item__wrapper {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 20px 50px #6666660D;
        border-radius: 8px;
		padding: 2.5rem;
		p {
			color: black;
			font-weight: bold;
			margin-top: 0.5rem;
            margin-bottom: 1.3rem;
		}
		label {
			color: #8e8e8e;
		}
		.work-item__description {
			font-weight: normal !important;
			display: flex;
			justify-content: flex-end;
		}
	}
    .work-item__control {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
}

.text-bold {
	font-weight: bold;
}
