@import "src/styles/variables";

.note {
	padding: 15px 12px;
	margin-bottom: 10px;
	.note__wrapper {
		display: flex;
		justify-content: space-between;
		.content {
			h5,
			p {
				margin: auto 0px;
			}

			p {
				color: $table-head;
			}
		}

		.content-image {
			display: flex;
			cursor: pointer;
			p {
				margin: auto 8px auto 0px;
			}

			.image-placeholder {
				background-color: white;
				height: 25px;
				width: 25px;
				margin-right: 12px;
			}
		}

		
	}
	.ant-divider.ant-divider-horizontal {
		margin: 12px 0;
	}
}

.banner-wrapper {
	display: flex;
	.banner-image {
		max-height: 80vh;
		max-width: 60vw;
		object-fit: cover;
	}
	.banner-control {
		button {
			display: block;
			margin-bottom: 2px;
			margin-left: 2px;
			background-color: white;
			border: none;
			font-size: 16px;
            width: 35px;
            padding: 4px 6px;
            cursor: pointer;
            outline: none;
			svg {
				color: black;
			}
		}
		button.danger {
			background-color: $danger-color;
			svg {
				color: white;
			}
		}
	}
}
