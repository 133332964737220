@import "src/styles/variables";

.roles-and-responsibility {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: $secondary-color;
    }
  }

  //  Checkbox
  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      height: 24px;
      width: 24px;
      &::after {
        border-color: $primary-white !important;
        left: 30% !important;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #2543e7 !important;
      }
    }
  }
}
