@import "src/styles/variables";

.issue-details {
    background-color: white;
    border-radius: 8px;
    box-shadow: $primary-shadow;
    padding: 1.75rem 2.75rem;

    label {
        color: rgba($primary-black, 0.5);
        font-size: 12px;
    }
}