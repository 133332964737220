@import "../../../styles/variables";

.corporate-object-note-form {
    background-color: $body-bg--dark;
    padding: 24px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &__title {
            color: $primary-color;
            font-weight: bold;
            font-size: 18px;
        }

        &__delete {
            .icon-delete {
                &:before {
                    color: inherit;
                }
            }
        }
    }

    &__content {
        padding: 20px;

        textarea {

            &:is(:active, :hover, :focus) {
                border: 1px solid $primary-border;
                box-shadow: none;
            }
        }

        &__footer {

            button.ant-btn {
                border: 0;
            }

            &__close {
                background-color: $card-bg--dark  !important;
            }
        }
    }
}