@import "src/styles/variables";

.treatment-template {
  .ant-pagination {
    .ant-select-selector,
    .ant-pagination-options {
      display: none;
    }
  }
  .treatment-template__title {
    display: flex;
    justify-content: space-between;
    p {
      margin: auto 0;
    }
  }

  .service-card {
    padding: 0.25rem;
    margin-top: 0.5rem;
    border-radius: 20px;
    text-align: center;
  }

  .treatment-template__control {
    display: flex;
  }
}
