@import "src/styles/variables";

.interaction-timeline {
	padding: 2rem;
	background-color: $card-bg--dark;
	border-radius: 4px;
}

.display-interaction__wrapper {
	width: 100%;
	height: 100%;
	.interaction-visit-details__stacked {
		border-radius: 4px;
	}
}

.current-interaction__detail {
	.ant-popover-arrow {
		display: none !important;
	}
	.ant-popover-inner-content {
		background-color: $card-light-bg--dark;
		.interaction-visit-details {
			height: 100%;
			padding: 1rem;
			background-color: white;
		}
		.babylon-scene {
			height: 100%;
		}
	}
}

.interaction-timeline__track {
	background-color: $card-light-bg--dark;
	border-radius: 50em;
	height: 15px;
}

.interaction-timeline__items {
	position: relative;
	margin: 0 2rem;
}

.interaction-timeline__track-item {
	position: absolute;
	top: 0;
	content: "";
	width: 0;
	height: 0;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid $card-light-bg--dark;
	cursor: pointer;
	&:hover, &.active {
		border-bottom-color: #ffffff;
	}
}
