.App{
    .html-object-embed.ck-widget:has(> input[type="radio"]){
        background: transparent;
        display: inline;
        outline: none;
        padding: 0;
        
        &::before{
            display: none;
        }
    }
}