@import "src/styles/variables";

/* Search Input */

.ant-input-affix-wrapper {
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: $primary-shadow;
  border: none;
  .ant-input-search-icon::before {
    border: none;
  }
}

/* Button */

.ant-btn {
  border-radius: 8px;
  font-weight: bold;
  font-size: 13px;
  
  &.large-btn {
    &:not(.ant-btn-icon-only) {
      padding: 10px 40px;
    }
  }

  &:not(.ant-btn-icon-only) {
    padding: 7px 20px;
    height: auto;
  }

  &.ant-btn-icon-only {
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    border: 1px solid transparent;
    background-color: $secondary-color;
    &.delete-btn {
      background-color: $danger-color;
    }
    &.ant-btn-primary {
      background-color: $primary-color;
    }
  }
}

.ant-btn.ant-btn-dashed {
  background-color: #5c5c5c;
  color: white;
  border: none;
}

.ant-tabs.ant-tabs-top.ant-tabs-card {
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      background: #e7e7e7;
      color: black;
      font-weight: normal;
      border-radius: 6px;
      margin-right: 12px;
    }
    .ant-tabs-tab-active {
      background: $secondary-color;
      div {
        color: white !important;
      }
    }
  }
}

.ant-tabs-nav-wrap {
}

.ant-btn-link {
  color: $primary-color;
  &:hover,
  &:active,
  &:focus {
    color: $primary-color;
  }
}

.ant-btn-default {
  border-color: transparent;
  color: $secondary-black;
  &:hover,
  &:active,
  &:focus {
    color: $secondary-black;
    border-color: transparent;
  }
}

.ant-btn-primary {
  border: 1px solid $primary-color;
  background-color: $primary-color;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $primary-color;
    background-color: $primary-color;
  }
}

.ant-btn.secondary-btn {
  background-color: $secondary-color;
  border: 1px solid $secondary-color;
  color: #ffffff;
  &:hover,
  &:active,
  &:focus {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: #ffffff;
  }
}
.ant-btn.blue-btn {
  background-color: $blue-secondary;
  border: 1px solid $blue-secondary;
  color: #ffffff;
  &:hover,
  &:active,
  &:focus {
    background-color: $blue-secondary;
    border: 1px solid $blue-secondary;
    color: #ffffff;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: $primary-light-color;
  color: #ffffff;
}

.secondary-btn[disabled],
.secondary-btn[disabled]:hover,
.secondary-btn[disabled]:focus,
.secondary-btn[disabled]:active {
  background-color: $secondary-light-color;
  border: 1px solid $secondary-light-color;
  color: #ffffff;
}

/* Table */

.ant-tabs-tab-disabled {
  display: none !important;
}
.ant-pagination {
  .ant-select-selector,
  .ant-pagination-options {
    display: none;
  }
}

.ant-table-wrapper {
  width: 100%;
  .ant-table .ant-table-content table {
    background-color: $body-bg;
    border-collapse: separate;
    border-spacing: 0 1rem;
  }

  thead.ant-table-thead {
    background-color: $body-bg;
    tr th {
      background-color: $body-bg;
      border: none;
      color: $secondary-black;
      font-weight: bold;
      padding-inline-start: 10px;
    }
  }

  tbody.ant-table-tbody tr {
    box-shadow: $primary-shadow;
    background-color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    & > td {
      border: none;
      padding: 10px;
    }
  }

  .ant-pagination-item-active {
    border-color: $primary-color;
  }
}

.ant-table-wrapper.secondary-table {
  .ant-table .ant-table-content table {
    background-color: #ffffff;
    thead.ant-table-thead tr th {
      background-color: #ffffff;
    }
    tbody.ant-table-tbody tr {
      background-color: $table-bg;
      box-shadow: none;
    }
  }
}

.table-no-title {
  .ant-table-thead {
    display: none;
  }
}

.table-no-pagination {
  .ant-pagination {
    display: none;
  }
}

/* Drawer */

.ant-drawer-header {
  background-color: $body-bg;
  box-shadow: $primary-shadow;
  padding: 1.5rem 2rem;
}

.ant-drawer-content {
  background-color: $body-bg;
  .ant-drawer-close {
    background-color: $danger-color;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    padding: 10px;
    font-size: 13px;
    color: #ffffff;
  }
}

/* Radio Group */
.ant-radio-group.ant-radio-group-solid {
  display: flex;
  .ant-radio-button-wrapper {
    display: flex;
    height: 42px;
    justify-content: center;
    align-items: center;

    &:hover {
      color: $secondary-color;
    }

    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }

    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
    color: white;
  }
}

/* Tabs */

.ant-tabs {
  color: $primary-black;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

.ant-tabs-ink-bar {
  background-color: transparent;
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 5px 20px;
  border-radius: 8px;
  .ant-tabs-tab-btn:hover {
    color: $secondary-color;
  }
  &.ant-tabs-tab-active {
    background-color: $card-primary-bg;
    .ant-tabs-tab-btn {
      color: $secondary-color;
      font-family: DMSansBold, sans-serif;
      &:hover {
        color: $secondary-color;
      }
    }
  }
}

// Pop over

.ant-popover.ant-popover-placement-rightTop,
.ant-popover.ant-popover-placement-rightBottom {
  width: 30vw;
  .ant-popover-content {
    box-shadow: 0px 5px 25px #66666629;
  }
  .ant-popover-inner {
    border-radius: 6px;
  }
}

// Dark popover

.pop-over__dark {
  .ant-popover-content {
    .ant-popover-inner {
      background: $input-bg--dark !important;
    }
  }
}

/* Modal */
.ant-modal.ant-modal-confirm {
  width: 35vw !important;
}

.ant-modal.primary-modal {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}
.ant-checkbox:hover {
  .ant-checkbox-inner {
    border-color: $primary-color !important;
  }
}
.ant-checkbox-checked::after {
  border-color: $primary-color !important;
}
.ant-radio:hover {
  .ant-radio-inner {
    border-color: $primary-color !important;
  }
}
.ant-radio-checked {
  .ant-radio-inner {
    border-color: $primary-color !important;
    &::after {
      background-color: $primary-color !important;
    }
  }
}

.ant-modal-mask {
  z-index: 1032;
}

.ant-modal-wrap {
  z-index: 1033;
}

.ant-modal {
  width: 70vw;
  .ant-modal-content {
    .ant-modal-close-x {
      background-color: $danger-color;
      color: white;
      border-radius: 100%;
      height: 30px !important;
      width: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -15px;
      margin-right: -15px;
    }
  }

  .ant-modal-footer {
    border-top: none !important;
    display: none;
  }
}

.dark__radio {
  span {
    color: #ccc;
  }
}

.modal-invisible {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-close-x {
    display: none !important;
  }
}

/* Tab Primary */

.ant-tabs.primary-tab {
  .ant-tabs-nav-list {
    border-radius: 50px !important;
    background-color: #4f4f4f !important;
    color: white;
    padding: 0px;
    .ant-tabs-tab {
      padding: 8px 34px !important;
      margin: 0px !important;
      .ant-tabs-tab-btn:active {
        color: $primary-color !important;
      }
      &.ant-tabs-tab-active {
        background-color: $primary-color;
        border-radius: 50px;
        .ant-tabs-tab-btn {
          color: white;
        }
      }
    }
  }
}

// Message

.ant-message-notice-content {
  background-color: #3a3a3a;
  border-radius: 8px;
  .ant-message-custom-content.ant-message-error {
    span {
      color: red;
      font-size: 18px;
    }
  }
}

// Radio button
.ant-radio-group.ant-radio-group-solid {
  .ant-radio-button-wrapper {
    border-radius: 4px !important;
    margin: 0px 8px !important;
    &.ant-radio-button-wrapper-checked {
      background-color: $primary-color;
      border-color: $primary-color;
      &::before {
        display: none;
      }
    }
  }
}

// danger btn

.btn-danger {
  background-color: #ea5858 !important;
  border-color: #ea5858 !important;
  cursor: pointer;
  &:hover {
    background-color: #ea5858 !important;
    border-color: #ea5858 !important;
  }
}
.delete-btn {
  width: 34px;
  height: 34px;
}
