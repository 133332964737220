@import "src/styles/variables";

.search-autocomplete{ 
    border-radius: 8px;
    .ant-input-wrapper.ant-input-group {
        border-radius: 8px;
        overflow: hidden;
    }
    .ant-input-group-addon {
        background-color: white;    
        button {
            background-color: white;
        }
    }
}

.auto-complete__title {
    color: $dark-grey;
}

.dashboard__search__focused {
    .ant-input-group-addon {
        background-color: $secondary-color;    
        button {
            background-color: $secondary-color;
            width: 45px;

            .anticon.anticon-search {
                color: white !important;
            }
        }
    }
}

// .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft  {
//     width: 80% !important;
// }