@import "src/styles/variables";

.responsible-person-detail {
}

.responsible-person-detail__label {
  color: $secondary-black;
  display: block;
  margin-bottom: 10px;
}

.responsible-person-detail__value {
  font-family: DMSansBold;
}
