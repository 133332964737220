.user-profile__wrapper {
  img {
    height: 1.7rem;
    border-radius: 100%;
  }

  .ant-col-16 {
    margin: auto;
  }
}

.visit-status__dropdown__items {
  width: 160px !important;
  .ant-select-item-option {
    padding: 12px;
  }
}

.visit-status__container {
  .img-wrapper {
    border-radius: 50px;
    padding: 0.2rem 0.4rem;
    img {
      height: 14px;
    }
  }

  .label {
    margin-left: 8px;
  }
}
.appointment-container {
  .ant-select.ant-select-single {
    width: 150px;
    .ant-select-selector {
      border: none;
      background-color: transparent;
    }
  }

  .ant-select-item.ant-select-item-option {
  }

  .visit-status__dropdown {
  }
}

.content-control {
  p {
    cursor: pointer;
  }
}

.appointment-container {
  .ant-select.ant-select-single {
    width: 180px;
    .ant-select-selector {
      border: none;
      background-color: transparent;
    }
  }
}

.content-control {
  p {
    cursor: pointer;
  }
}
