@import "src/styles/variables";

.new-appointment-form {
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;

  .ant-col-24 {
    .new-appointment-form__note {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &__label {
        color: $secondary-black;
        font-weight: bold;
      }

      &__link {
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
}