@import "src/styles/variables";

.patient-detail__label {
  color: $secondary-black;
  display: block;
  margin-bottom: 5px;
}

.patient-detail__value {
  font-weight: bold;
}
