@import "src/styles/variables";

.select-corporate-objects {
  .ant-table-wrapper .ant-table .ant-table-content table {
    background-color: #ffffff;
  }

  input {
    border-radius: 8px;
    width: 40%;
  }

  .ant-checkbox-wrapper {
    > span.ant-checkbox {
      width: 17px;
    }
    > span {
      width: 80px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .ant-checkbox-inner::after {
    top: -42%;
    left: 17%;
  }

  .ant-checkbox-wrapper {
    width: 100px;
  }
}

.select-corporate-objects__color {
  display: block;
  width: 150px;
  height: 30px;
  border: 1px solid $primary-border;
  border-radius: 4px;
}

.select-corporate-objects__body {
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  padding: 2rem;
  border-radius: 8px;
  h5 {
    padding-bottom: 10px;
    border-bottom: 1px solid $primary-border;
  }
}

.select-corporate-objects__footer {
  margin-top: 1rem;
  text-align: right;
}
