@import "src/styles/variables";

.status-management__container {
	.appointment-title {
		color: $secondary-color;
	}
	.status-tabs {
		background-color: white;
		margin-top: 1rem;
		padding: 1.5rem;
		padding-bottom: 0;
        border-radius: 10px 10px 0px 0px;

		.ant-tabs-nav{
			margin-bottom: 0;
		}
	}
}
