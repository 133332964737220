@import "variables";
@import "themes";
@import "antOverrides";
@import "helpers";
@import "icons";

/* General component styles */

@font-face {
  font-family: DMSansRegular;
  src: url("../assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: DMSansMedium;
  src: url("../assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: DMSansBold;
  src: url("../assets/fonts/DMSans-Bold.ttf") format("truetype");
}

body {
  background-color: $body-bg;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: DMSansMedium, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: DMSansBold, sans-serif;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.2rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.9rem;
}

.theme--default {
  .app-routes .app-wrapper {
    transition: margin 0.25s;
    padding: 1rem;
  }
}

.a4d-router-wrapper {
  @include themed() {
    background-color: setStyle("body-bg");
    color: setStyle("text-color");
  }

  .theme--default &:has(.app-sidebar.collapsed){
    .app-wrapper{
      margin: $app-wrapper-margin--collapsed;
      width: 90%;
    }
  }
}

.app-wrapper {
  @include themed() {
    margin: setStyle("app-wrapper-margin");
    width: setStyle("app-wrapper-width");
    background-color: setStyle("body-bg");
  }
  padding: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.full-width {
  width: 100%;
}

.ck.ck-content.ck-editor__editable {
  min-height: 25vh !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.warning-text {
  color: $warning-color;
}
.success-text {
  color: $success-color;
}

.text-strike-through {
  text-decoration: line-through;
}

.theme--dark {
  .check-list-icon__wrapper {
    display: none !important;
  }
}
.check-list-icon__wrapper {
  position: fixed;
  bottom: 100px;
  right: 10px;
  background-color: $primary-color;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 29px #00000066;
  .anticon.anticon-unordered-list {
    color: white;
    font-size: 18px;
  }
}

.ant-btn.icon-btn {
  background-color: $card-bg--dark;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  color: $primary-white;
  border: 1px solid $card-bg--dark;
  i {
    font-size: 12px;
  }
  img {
    height: 0.9rem;
  }
}

.btn-blink {
  animation: blink 0.5s infinite;
}

.visibility-hidden {
  visibility: hidden;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.display-none {
  display: none !important;
}

.content-wrapper-shadow {
  border-radius: 6px;
  background: white;
  padding: 2.5rem;
  box-shadow: 0px 2px 20px #5858580d;
}

.height-full {
  height: 100%;
}

.text-secondary {
  color: $secondary-color;
}

.text-success {
  color: $success-color;
}

.text-warning {
  color: $warning-color;
}

.text-black {
  color: $primary-black !important;
}

.hint {
  color: $secondary-black;
}
