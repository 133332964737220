.interaction-form__container {
	.interaction-form__wrapper {
        background-color: white;
		box-shadow: 0px 20px 50px #6666660d;
		border-radius: 8px;
        padding: 2.7rem;
	}
    .interaction-form__control{ 
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        .ant-btn.ant-btn-primary {
            margin-left: 12px;
        }
    }
}
