@import "src/styles/variables";

.patient-dashboard {
  .ant-pagination {
    .ant-select-selector,
    .ant-pagination-options {
      display: none;
    }
  }
}

.patient-dashboard__add-wrapper {
  margin-top: 5rem;
  box-shadow: $primary-shadow;
  padding: 3rem;
  border-radius: 8px;
}

.patient-dashboard__table-wrapper {
  margin-top: 5rem;
}

.patient-email {
  color: $primary-color;
}

.patient-option__wrapper {
  padding: 0.4rem 0.8rem;
}
