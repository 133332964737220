@import "src/styles/variables";

.insurance-detail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    margin-top: 16px;

    &__inline-start {
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 24px;

        &__header {
            align-items: center;
            display: flex;
            gap: 15px;
            grid-column: 1 / span 2;
            margin-bottom: 30px;

            &__title {
                align-items: center;
                background: #f2fffc 0% 0% no-repeat padding-box;
                border-radius: 8px;
                border: 0.5px solid $primary-color;
                color: $primary-color;
                display: flex;
                height: 32px;
                justify-content: center;
                opacity: 1;
                width: 150px;
            }
        }

        &__grid {
            display: grid;
            row-gap: 36px;
            grid-template-columns: repeat(2, 1fr);

            &__left,
            &__right {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }

    &__inline-end {
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 24px;

        .ant-table-wrapper {
            background: none;

            .ant-table {
                background: none;

                table {
                    background: none !important;

                    thead,
                    th {
                        background: none;
                    }

                    tbody.ant-table-tbody {
                        tr.ant-table-row {
                            background: none;
                            border-radius: 0;
                            box-shadow: none;
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        &__summary {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            gap: 10px;
            margin-top: 10px;


            &__label {
                color: #4D4F5C;
                font-size: 12px;
            }

            &__value {
                color: $primary-black;
                font-size: 18px;
            }
        }
    }

    &__field {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &__label {
            font-size: 12px;
            color: #4D4F5C;
        }

        &__value {
            font-size: 18px;
            font-weight: bold;
        }
    }
}