@import "src/styles/variables";

.object-notes {
}

.object-notes__item {
  // background-color: $light-white;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 1rem;
}

.object-notes__actions {
  text-align: right;
  span {
    cursor: pointer;
    border: 1px solid $light-grey;
    padding: 10px;
    border-radius: 50em;
  }
  .object-notes__delete {
    color: $danger-color;
  }
}
