@import "src/styles/variables";
.reports {
  .reports__payment {
    margin-top: 2rem;
    padding: 2rem;
    background-color: $primary-white;
    .reports__download-icon {
      img {
        cursor: pointer;
        width: 34px;
        height: 34px;
      }
    }

    .reports__select {
      width: 150px;
      .ant-select-selector {
        border-radius: 8px !important;
        height: 40px;
        .ant-select-selection-placeholder {
          margin-top: 5px;
        }
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-picker {
      width: 150px;
      height: 40px;
      border-radius: 8px !important;
      .ant-picker-input input {
        text-align: center;
      }
    }
    .reports__stats {
      margin-top: 2rem !important;
      h4 {
        font-size: 15px;
        color: $primary-black;
      }
      p {
        font-size: 13px;
        color: $card-light-bg--dark;
        font-weight: lighter;
      }
      .reports__stats-total {
        width: 160px;
        height: 62px;
        background: #eff2ff 0% 0% no-repeat padding-box;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .amount {
          font-size: 18px;
          font-weight: bold;
          color: $secondary-color;
        }
        .title {
          font-size: 13px;
          color: $card-light-bg--dark;
        }
      }
    }
    .reports__payments-table {
      margin-top: 2rem !important;
      h4 {
        font-size: 15px;
        color: $primary-black;
      }
    }
  }
  .reports__outstanding-payment {
    width: 170px;
    align-items: center;

    display: flex;
    justify-content: space-between;
  }
}
