@import "src/styles/variables";

.detailed-onboarding-basic-details {

}

.detailed-onboarding-basic-details__add-wrapper {
  position: fixed;
  top: 1.8rem;
  z-index: 2;
  right: 4rem;
}

.detailed-onboarding-basic-details__form,
.detailed-onboarding-basic-details__practice-list,
.detailed-onboarding-basic-details__staff-list {
  color: $primary-black;
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  padding: 2rem 3rem;
  margin-bottom: 2rem;
}
