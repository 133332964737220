@import "src/styles/variables";

.appointment-day-view {
  background-color: #fff;
  color: black;
  padding: 10px;

  .rbc-day-slot {
    .rbc-events-container {
      margin-right: 0;
    }
  }

  .rbc-event {
    border-radius: 0;
    text-align: center;

    &-content {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: stretch;
    }
  }

  .rbc-header,
  .rbc-day-slot.rbc-time-column {
    min-width: 200px;
  }
}

.rbc-time-view {
  border-width: 0;

  .rbc-time-header {
    border: 0;

    &-content {
      border-left-width: 0;

      .rbc-row {
        border-bottom-width: 0;
      }
    }
  }

  .rbc-label {
    border: 0;
  }

  .rbc-header {
    border: 0;
    color: $table-head;
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-content {
    border: 0;

    .rbc-label {
      color: $card-light-bg--dark;
      font-size: 12px;
    }

    .rbc-timeslot-group {
      border-bottom-width: 0;
      min-height: 20px;
      max-height: 20px;
    }
  }

  .rbc-day-slot {
    cursor: pointer;

    .rbc-time-slot {
      border-top: 1px dashed $primary-border;
    }

    // .rbc-timeslot-group {
    //   border-bottom: 1px solid #ddd;
    // }
  }
}
.add-appointment {
  cursor: pointer !important;
}
