@import "src/styles/variables";

.treatment-template-details {
	height: calc(100vh - 2rem);
	.ant-btn.ant-btn-icon-only,
	.ant-btn.icon-btn {
		background-color: $card-bg--dark;
		padding: 10px;
		width: 40px;
		height: 40px;
		margin-left: 10px;
		color: $primary-white;
		border: 1px solid $card-bg--dark;
		i {
			font-size: 12px;
		}
		img {
			height: 0.9rem;
		}
	}
}

.ant-popover.treatment-plan-viewer__popover {
	.ant-popover-content {
		&.ant-popover-arrow {
			display: none;
		}
	}
	.ant-popover-inner-content {
		background-color: $card-bg--dark;
		box-shadow: $primary-shadow;
		width: 350px;
		color: $primary-white;
		padding: 16px;
	}
}
