@font-face {
  font-family: "icomoon";
  src: url("../assets/icons/icomoon.eot?5v7eje");
  src: url("../assets/icons/icomoon.eot?5v7eje#iefix")
      format("embedded-opentype"),
    url("../assets/icons/icomoon.ttf?5v7eje") format("truetype"),
    url("../assets/icons/icomoon.woff?5v7eje") format("woff"),
    url("../assets/icons/icomoon.svg?5v7eje#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-configuration:before {
  content: "\e900";
}
.icon-contract:before {
  content: "\e901";
}
.icon-Corporates:before {
  content: "\e902";
}
.icon-custom-form:before {
  content: "\e903";
}
.icon-dashboard:before {
  content: "\e904";
}
.icon-date:before {
  content: "\e905";
}
.icon-delete:before {
  content: "\e906";
  color: #4f4f4f;
}
.icon-documents:before {
  content: "\e907";
}
.icon-edit:before {
  content: "\e908";
  color: #4f4f4f;
}
.icon-elastic:before {
  content: "\e909";
}
.icon-flag:before {
  content: "\e90a";
}
.icon-image:before {
  content: "\e90b";
}
.icon-layer1:before {
  content: "\e90c";
}
.icon-medical-emergency:before {
  content: "\e90d";
}
.icon-note-template:before {
  content: "\e90e";
}
.icon-oral-hygine:before {
  content: "\e90f";
}
.icon-patient-details:before {
  content: "\e910";
}
.icon-patint-details:before {
  content: "\e911";
}
.icon-payments:before {
  content: "\e912";
}
.icon-post-visit:before {
  content: "\e913";
}
.icon-practices:before {
  content: "\e914";
}
.icon-pre-visit:before {
  content: "\e915";
}
.icon-print:before {
  content: "\e916";
  color: #4f4f4f;
}
.icon-procedure:before {
  content: "\e917";
}
.icon-publish:before {
  content: "\e918";
  color: #4f4f4f;
}
.icon-remove:before {
  content: "\e919";
}
.icon-resources:before {
  content: "\e91a";
}
.icon-roles-permissions:before {
  content: "\e91b";
}
.icon-services:before {
  content: "\e91c";
}
.icon-sets:before {
  content: "\e91d";
}
.icon-settings:before {
  content: "\e91e";
}
.icon-space-management:before {
  content: "\e91f";
}
.icon-staff:before {
  content: "\e920";
}
.icon-Statement-notes:before {
  content: "\e921";
}
.icon-status-management:before {
  content: "\e922";
}
.icon-subscriptions:before {
  content: "\e923";
}
.icon-summary:before {
  content: "\e924";
}
.icon-support:before {
  content: "\e925";
}
.icon-teeth:before {
  content: "\e926";
}
.icon-time:before {
  content: "\e927";
}
.icon-total-value1:before {
  content: "\e928";
}
.icon-total-value2:before {
  content: "\e929";
}
.icon-total-value3:before {
  content: "\e92a";
}
.icon-treatment-plans:before {
  content: "\e92b";
}
.icon-visit-details:before {
  content: "\e92c";
}
.icon-visits:before {
  content: "\e92d";
}
.icon-wire:before {
  content: "\e92e";
}
.icon-X-ray-image:before {
  content: "\e92f";
}
.icon-X-Ray-Notes:before {
  content: "\e930";
}
.icon-your-tickets:before {
  content: "\e931";
}
.icon-zoom-in:before {
  content: "\e932";
}
.icon-zoom-out:before {
  content: "\e933";
}
.icon-d-core:before {
  content: "\e934";
}
.icon-aligners:before {
  content: "\e935";
}
.icon-analytics:before {
  content: "\e936";
}
.icon-appliances:before {
  content: "\e937";
}
.icon-bracket:before {
  content: "\e938";
}
.icon-branding:before {
  content: "\e939";
}
.icon-calendar:before {
  content: "\e93a";
  color: #fafafa;
}
.icon-card-details:before {
  content: "\e93b";
}
.icon-Comment-Notes:before {
  content: "\e93c";
}
