@import "src/styles/variables";

.staff-list {
  .ant-switch {
    background-color: $danger-color;
    .ant-switch-handle::before {
      background-color: $danger-color;
      border: 3px solid #ffffff;
    }
  }
  .ant-switch-checked {
    background-color: $primary-color;
    .ant-switch-handle::before {
      background-color: $primary-color;
      border: 2px solid #ffffff;
    }
  }
}
.user-option__wrapper {
  padding: 0.4rem 0.8rem;
}
.user-email {
  color: $primary-color;
}

.present-but-not {
  visibility: hidden;
  pointer-events: none;
}
