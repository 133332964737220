@import "src/styles/variables";

.resource-details__container {  
    .resource-details__wrapper {
        margin-bottom: 1.5rem;
        p {
            margin: auto 0;
        }
        .resource-details__topic {
            color: $secondary-black;
            margin-bottom: 10px;
        }
        .resource-details__value {
            color: $primary-black;
        }
    }
    .resource-details__edit {
        i::before {
            color: white;
            margin-right: 10px;
        }
    }
    .resource-details__status {
        margin: auto;
        label {
            color: $secondary-black;
        }
    }
}