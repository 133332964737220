.splash-screen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: black;
  z-index: 99999;
  background-image: linear-gradient(180deg, #1c2f80, #00ae89);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  animation: fadeout 2s infinite;
  img {
    height: 100;
    width: 100px;
    animation: blip 0.7s infinite;
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blip {
  0% {
    height: 100px;
    width: 100px;
  }

  50% {
    height: 105px;
    width: 105px;
  }

  100% {
    height: 100px;
    width: 100px;
  }
}
