@import "src/styles/variables";

.patient-detail-tabs {
  .ant-tabs-tabpane {
    margin-top: 2rem;
  }

  .ant-tabs-tab-btn i {
    margin-right: 5px;
  }
}

.patient-detail-tabs__edit-wrapper {
  text-align: right;
}

.patient-detail-tabs__tab-wrapper {
  margin-top: 2rem;
}
