@import "src/styles/variables";

.procedure-detail {
  width: 100%;
  height: calc(100vh - 2rem);
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.procedure-detail__header {
  .ant-btn.ant-btn-icon-only,
  .ant-btn.icon-btn {
    background-color: $card-bg--dark;
    padding: 10px;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    color: $primary-white;
    border: 1px solid $card-bg--dark;
    i {
      font-size: 12px;
    }
  }
}

.procedure-detail__actions-row {
  margin-top: 2rem;
}

.procedure-detail__actions-wrapper {
  background-color: $object-preview-bg;
  padding: 1rem;
  border-radius: 4px;
}

.procedure-detail__actions-wrapper,
.procedure-detail__notes-wrapper {
  height: 80vh;
  overflow: hidden scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.procedure-detail__notes-wrapper {
  .ant-collapse {
    border: none;
    background-color: $card-bg--dark;
  }

  .ant-collapse-item {
    margin-bottom: 1rem;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: $primary-white;
    background-color: $card-light-bg--dark;
    border: none;
  }
}

.procedure-detail__notes-wrapper {
  background-color: $card-bg--dark;
  padding: 1rem;
  border-radius: 4px;
}

.procedure-detail__field {
  margin-bottom: 10px;
}

.procedure-detail__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.procedure-detail__set-item.ant-collapse {
  background-color: $light-white;
  border: none;
  border-radius: 8px;
  > .ant-collapse-item {
    background-color: $light-white;
    border-radius: 8px;
    &:last-child {
      border-radius: 8px;
      > .ant-collapse-header {
        border-radius: 8px;
      }
    }
    > .ant-collapse-header {
      border: none;
      background-color: $light-white;
      color: $primary-black;
      border-radius: 8px;
      padding: 1rem;
      .ant-collapse-arrow {
        right: 12px;
        top: 8px;
      }
    }
    .ant-collapse-content {
      border: none;
      background-color: $light-white;
    }
    .procedure-detail__set-header-row {
      svg {
        color: $danger-color;
        cursor: pointer;
        margin-top: -1px;
      }
    }
  }
}

.procedure-detail__link-item {
  background-color: $light-white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  svg {
    color: $danger-color;
    cursor: pointer;
  }
}

.procedure-detail__set-step-item {
  background-color: $light-white;
  border-radius: 8px;
  margin-bottom: 5px;
}

.ant-popover.procedure-detail__popover {
  > .ant-popover-content > .ant-popover-arrow {
    border-top-color: $card-bg--dark;
    border-left-color: $card-bg--dark;
  }
  .ant-popover-inner {
    background-color: $card-bg--dark;
    width: 400px;
    padding: 1rem;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
  .procedure-form__form-body {
    padding: 0;
    background-color: $card-bg--dark;
  }
  .ant-btn.ant-btn-icon-only {
    background-color: $card-light-bg--dark;
    padding: 10px;
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
  .input-field-wrapper {
    margin-bottom: 1rem;
  }
  input.ant-input.input-field,
  input.ant-input,
  textarea {
    border: 1px solid $primary-border;
    border-radius: 8px;
    padding: 12px 15px;
    background-color: $input-bg--dark;
    color: $primary-white;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector {
    padding: 7px 15px;
    border: 1px solid $primary-border;
    height: auto;
    border-radius: 8px;
    background-color: $input-bg--dark;
    color: $primary-white;
  }
  .ant-select-arrow {
    color: $primary-white;
  }
  .procedure-form__submit-wrapper {
    margin-top: 0;
  }
}
