@import "src/styles/variables";

.contract-detail {
  &__edit-btn {
    height: 43px !important;
  }

  .contract-detail__btn {
    .contract-detail__btn-modifier {
      display: flex-end;
      justify-content: center;
      align-items: center;
      padding: 8px 20px !important;
      max-height: 45px !important;

      .contract-detail__btn-icon {
        width: 26px;
        object-fit: contain;
      }
    }
  }
}

.ant-tabs.contract-detail__tabs {
  width: 100%;
  .ant-tabs-tab-btn i {
    margin-right: 5px;
  }
  .ant-tabs-tabpane {
    background-color: #ffffff;
    padding: 3rem 4rem;
    border-radius: 8px;
    box-shadow: $primary-shadow;
  }
}
