@import "src/styles/variables";

.empty-placeholder {
    background-color: white;
    box-shadow: 0px 20px 50px #6666660D;
    border-radius: 8px;
    margin-top: 1.75rem;
    .ant-row {
        padding: 3rem 2rem;
        div {
            &:first-child {
                max-height: 12rem;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
    
            &:last-child {
                h3 {
                    margin: auto 0 6px;
                    font-size: 20px;
                }
                 
                p {
                    color: $card-light-bg--dark;
                }
    
    
            }
        }
    }

}