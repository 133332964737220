@import "src/styles/variables";

.payments {
  //

  .icon-edit {
    background-color: $primary-color;
    padding: 0.75rem;
    border-radius: 8px;
    &::before {
      color: white;
    }
  }

  .icon-delete {
    background-color: $danger-color;
    padding: 0.75rem;
    border-radius: 8px;
    &::before {
      color: white;
    }
  }
  .icon-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.payments__select {
  background: #ffffff 0% 0% no-repeat padding-box;

  opacity: 1;
  .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px !important;
    height: 40px !important;
    border-radius: 8px !important;
  }
}

.payments__warning-modal {
  display: flex;
  text-align: center;
  height: 230px;
  img {
    width: 30px;
  }
  .ant-modal-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 50px #6666660d;
    border-radius: 18px;
    opacity: 1;

    button {
      .ant-modal-close-x {
        color: #acacac;
        background-color: transparent;
        margin: 10px 10px;
        width: 20px !important;
        height: 20px !important;
      }
    }

    .ant-modal-body {
      margin-top: 50px;
      height: 200px !important;

      p {
        text-align: center;
        margin-top: 80px;
      }
    }
  }
}
.ant-table-wrapper {
  width: 100%;
  .ant-table .ant-table-content table {
    background-color: $body-bg;
    border-collapse: separate;
    border-spacing: 0 !important;
  }
}

.payments__update-status {
  .ant-modal-content {
    border-radius: 8px !important;
    width: 700px;
  }
}
