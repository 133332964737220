@import "src/styles/variables";

.notification {
  background-color: #f7f7f7;
  display: flex;
  padding: 1rem 1.5rem;
  border-radius: 6px;
  position: relative;
  margin-bottom: 0.5rem;
  cursor: pointer;
  .img-wrapper {
    margin-right: 12px;
    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .content {
    p {
      margin: 0px;
      font-size: 16px;
    }
    .name {
      color: $secondary-color;
    }
    .message {
      color: $primary-black;
    }
  }

  .time {
    position: absolute;
    top: 8px;
    right: 8px;
    color: rgba($color: #000000, $alpha: 0.4);
    .dot {
      background-color: rgba($color: #000000, $alpha: 0.3);
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 4px;
    }
  }
}
