@import "src/styles/variables";

.app-badge {
  background-color: $primary-color;
  color: #FFFFFF;
  border-radius: 25px;
  padding: 5px 15px;
  line-height: 15px;
  font-size: 12px;
}
