@import "src/styles/variables";

.rbc-calendar {
  color: black;

  .rbc-event {
    border-radius: 0;
    text-align: center;

    &-content {
      font-size: 12px;
    }
  }


  .rbc-time-view {
    border-width: 0;

    .rbc-time-header {
      border: 0;

      &-content {
        border-left-width: 0;

        .rbc-row {
          border-bottom-width: 0;
        }
      }
    }

    .rbc-label {
      border: 0;
    }

    .rbc-header {
      border: 0;
      color: $table-head;
      font-size: 12px;
      font-weight: normal;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    .rbc-allday-cell {
      display: none;
    }

    .rbc-time-content {
      border: 0;

      .rbc-label {
        color: $card-light-bg--dark;
        font-size: 12px;
      }

      .rbc-timeslot-group {
        border-bottom-width: 0;
        min-height: 20px;
        max-height: 20px;
      }
    }

    .rbc-day-slot {
      cursor: pointer;

      .rbc-time-slot {
        border-top: 1px dashed $primary-border;
      }

      .event-container-empty .rbc-events-container {
        border: 1px dashed $primary-color;
        background-color: #f8fffe9a;

        &::before {
          content: "Right Click and Book Slot Creation";
          color: $primary-color;
          position: absolute;
          top: 50%;
          left: 25%;
        }
      }

      // .rbc-timeslot-group {
      //   border-bottom: 1px solid #ddd;
      // }
    }
  }
}

.appointment-table {
  background-color: $primary-white;
  padding: 1rem;
  width: 100%;

  .appointment-table__time {
    margin-top: 60px;
    border: none;

    td {
      padding-bottom: 10px;
      border: none;

      // padding: 10px 0;
    }
  }
}

.appointment-table__wrapper {
  .appoitment-table__inline {
    display: inline-block;
    margin-left: 2px;
  }

  .appointment-table__col {
    background: #f8fffe9a 0% 0% no-repeat padding-box;

    border-collapse: separate;
    border-spacing: 20px 0;
    // padding: 0 5px 0 0;
    width: 100%;
    height: 100%;

    td {
      width: 200px;
      text-align: center;
      // border: 2px dotted #00ae89 !important;
      border-bottom: 0.1px !important;
    }

    th {
      text-align: center;
      margin-bottom: 50px !important;
      color: #777c80;
      font-weight: lighter;
      padding-bottom: 20px;
    }
  }
}