@import "src/styles/variables";

.user-schedule {
  .ant-btn.ant-btn-primary {
    i:before {
      color: #ffffff;
    }
  }
}

.ant-btn.user-schedule__add-button.ant-btn-icon-only {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: $primary-color;
  box-shadow: $primary-shadow;
  z-index: 1;
  border-radius: 50em;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  svg {
    font-size: 2rem;
  }
}

.user-schedule__navigation-wrapper .ant-btn {
  margin-right: 10px;
  &.ant-btn-icon-only {
    background-color: #ffffff;
    border: 1px solid $primary-border;
    color: $secondary-black;
  }
  &:not(.ant-btn-icon-only) {
    padding: 5px 30px;
  }
}

.user-schedule__calendar {
  padding: 2rem 1rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  margin-top: 2rem;
  border-radius: 4px;
}

.user-schedule__label {
  color: $secondary-black;
  text-align: center;
}

.user-schedule__schedules {
  margin-top: 2rem;
}

.user-schedule__schedule-col {
  border: 1px solid $primary-border;
  padding: 1rem 10px;
}

.user-schedule__slot-item {
  height: 200px;
  background-color: #ffffff;
  box-shadow: $slot-shadow;
  border-radius: 0 0 8px 8px;
  margin-bottom: 1rem;
  position: relative;
  padding: 1rem 10px;
  border-top: 0;
  cursor: pointer;
  .user-schedule-slot-item-body {
    height: 100%;
  }
  &::before {
    position: absolute;
    content: "";
    top: -4px;
    left: -1px;
    width: calc(100% + 2px);
    height: 4px;
    background-color: $primary-color;
    border-radius: 8px 8px 0 0;
  }
}

.user-schedule__user-details {
  background-color: $secondary-color;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  > div {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
  img {
    width: 100%;
    display: block;
    border-radius: 50%;
  }
  h5 {
    display: block;
    color: #ffffff;
    margin-top: 10px;
  }
  .user-schedule__user-role {
    margin: 0.25rem 0;
  }
}

.user-schedule__slot-time {
  color: $warning-color;
  font-size: 12px;
}

.user-schedule__empty-slot {
  background-color: $secondary-light-color;
  border-radius: 8px;
  height: 100%;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.user-schedule__availability-types {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
  padding: 10px;
}

.user-schedule__availability-type {
  color: $secondary-black;
  margin-bottom: 5px;
  i:before {
    color: $secondary-color;
  }
}

.ant-dropdown.user-schedule__actions-menu.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  .ant-dropdown-arrow {
    border-top-color: $primary-black;
    border-left-color: $primary-black;
  }
  .ant-dropdown-menu.ant-dropdown-menu-vertical {
    border: none;
    background-color: $primary-black;
    border-radius: 8px;
    padding: 0.5rem;
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-item-active,
    i:before {
      background-color: $primary-black;
      color: #ffffff;
    }
  }
}
