@import "src/styles/variables";

.app-notification {
  width: 400px;
  padding: 1rem 0.7rem;
  .clear-all {
    text-align: right;
    color: rgba($color: $primary-black, $alpha: 0.6);
    cursor: pointer;
    i {
      font-size: 10px;
    }

    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ant-tabs.ant-tabs-top {
    .ant-tabs-nav-list {
      width: 100%;
      padding: 0px;
      border-radius: 6px !important;
      overflow: hidden;
      background-color: #f0f0f0;
      .ant-tabs-tab {
        width: 100%;
        padding: 10px 34px;
        margin: 0px;
        .ant-tabs-tab-btn {
          color: #92949a !important;
          font-weight: normal !important;
        }
        &.ant-tabs-tab-active {
          background-color: $secondary-color;
          border-radius: 6px;
          .ant-tabs-tab-btn {
            color: white !important;
          }
        }
      }
    }
  }
  .time {
    .dot {
      background-color: rgba($color: #000000, $alpha: 0.3);
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 4px;
    }
    position: absolute;
    top: 8px;
    right: 8px;
    color: rgba($color: #000000, $alpha: 0.4);
  }
}
