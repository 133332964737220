@import "src/styles/variables";

.stripe-card {
  box-shadow: 0px 20px 50px #6666660d;
  padding: 0.7rem 1rem;
  position: relative;
  border-radius: 8px;
  transition: 0.5s padding;

  &:hover {
    cursor: pointer;
  }

  p {
    margin-bottom: 4px !important;
  }
  .title {
    color: $secondary-black;
  }

  .value {
    color: $primary-black;
  }

  .name {
    position: absolute;
    top: 12px;
    right: 1.5rem;
    color: #6772e5;
  }

  .visa {
    height: 42px;
  }

  .stripe-img {
    display: flex;
    align-items: center;
  }
}

.stripe-card-selected {
  border: 1px solid $primary-color;
}
