@import "src/styles/variables";

.mouth-slider {
	.babylon-viewer__slider__container {
		width: 20%;
		.babylon-viewer__slider {
			width: 100%;
			margin: auto !important;
			.ant-slider-track {
				background-color: $primary-color !important;
			}
			.ant-slider-handle {
				background-color: $primary-color !important;
				border-color: $primary-color !important;
			}
		}
	}

	.babylon-horizontal {
		display: flex;
		position: absolute;
		left: 60px;
		top: 30px;
		img {
			height: 18px;
			&:first-child {
				margin-right: 12px;
			}
			&:last-child {
				margin-left: 12px;
			}
		}
	}

	.babylon-vertical {
		position: absolute;
		top: 70px;
		left: 40px;
		height: 18%;
		width: auto;
		.babylon-viewer__slider {
			margin-left: 3px !important;
		}
		img {
			height: 18px;
			&:first-child {
				margin-bottom: 12px;
			}
			&:last-child {
				margin-top: 12px;
			}
		}
	}
}
