@import "src/styles/variables";

.currency-field {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__label {
        color: $secondary-black;
        font-size: 16px;
    }

    &__input {
        border: 1px solid #bebebe;
        border-radius: 8px;
        padding: 12px 15px;
        outline: none;

        &::placeholder {
            color: $secondary-black;
            opacity: 0.5;
        }
    }

    .hint {
        font-size: 11px;
        color: $danger-color;
    }
}