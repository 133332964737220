.allocated-slot-popup {
    .ant-popover-inner {
        border-radius: 4px;
        cursor: pointer;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.allocated-slot {
    &__status-popup {
        $self: &;

        .ant-modal-header {
            border-bottom: 0;
            border-top-left-radius: 8px;
        }

        .ant-modal-content {
            border-radius: 8px;
            padding: 15px;

            .ant-modal-body {
                padding-top: 10px;

                form {
                    display: flex;
                    flex-direction: column;
                }

                #{$self}__btn {
                    margin-left: auto;
                }
            }

        }

    }
}