@import "src/styles/variables";

.dental-object-preview {
  height: 100%;
}

#a4d-dental-object {
  width: 100% !important;
  height: 100%;
  &:focus {
    outline: none;
  }
}
