@import "src/styles/variables";

.ant-modal.confirmation-dialog {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-body {
    padding: 2rem;

    h5 {
      margin-bottom: 1.5rem;
    }
  }
}
