@import "src/styles/variables";

.resource-edit-icon {
    background-color: $primary-color;
    padding: .6rem;
    border-radius: 8px;
    margin-right: 10px;
    &::before {
        color: white !important;
    }
}

.resource-delete-icon {
    background-color: $danger-color;
    padding: .6rem;
    border-radius: 8px;
    &::before {
        color: white !important;
    }
}
 
.resource__container {
    .resource-header__wrapper {
        display: flex;
        justify-content: space-between;
        h4 {
            color: $secondary-color;
            .add-resource-icon {
                font-weight: bold;
            }
        }
    }
}