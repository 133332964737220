@import "src/styles/variables";

.overall-user-schedules {
}

.overall-user-schedules {
  .ant-btn.ant-btn-primary {
    i:before {
      color: #ffffff;
    }
  }
}
.ant-btn.overall-user-schedules__add-button.ant-btn-icon-only {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: $primary-color;
  z-index: 1;
  border-radius: 50em;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  svg {
    font-size: 2rem;
  }
}

.overall-user-schedules__navigation-wrapper .ant-btn {
  margin-right: 10px;
  &.ant-btn-icon-only {
    background-color: #ffffff;
    border: 1px solid $primary-border;
    color: $secondary-black;
  }
  &:not(.ant-btn-icon-only) {
    padding: 5px 30px;
  }
}

.overall-user-schedules__calendar {
  padding: 2rem 1rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  margin-top: 2rem;
  border-radius: 4px;
  .ant-select.ant-select-single {
    width: 100%;
    .ant-select-selector {
      border-radius: 8px;
    }
  }
}

.overall-user-schedules__label {
  color: $secondary-black;
  text-align: center;
}

.overall-user-schedules__schedules {
  margin-top: 2rem;
}

.overall-user-schedules__schedule-col {
  border: 1px solid $primary-border;
  padding: 1rem 10px;
}

.overall-user-schedules__user-details {
  border: 1px solid $primary-border;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  > div {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
  img {
    width: 100%;
    display: block;
    border-radius: 50%;
  }
  h5 {
    display: block;
    margin-top: 10px;
  }
  .user-schedule__user-role {
    margin: 0.25rem 0;
  }
}
