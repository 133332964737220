@import "src/styles/variables";

.corporate-object-filters__body {
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  padding: 1rem 2rem 2rem;
  border-radius: 8px;
}

.corporate-object-filters__footer {
  margin-top: 1rem;
  text-align: right;
}

.ant-collapse.corporate-object-filters__collapse {
  border: none;
  .ant-collapse-content {
    border: none;
  }
  > .ant-collapse-item {
    border: none;
    > .ant-collapse-header {
      background-color: #ffffff;
      border-bottom: 1px solid $primary-border;
      padding: 15px 16px;
    }
  }
}
