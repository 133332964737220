.photo-consent-options {
    .radio-input-field {
        background-color: #e4e4e4;
        padding: 25px 50px;
        border-radius: 10px;

        .ant-radio-group-outline {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}

.photo-consent-confirmation-modal {
    .ant-modal-content {
        border-radius: 18px;

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            gap: 15px;
            justify-content: center;

            .btn-grp {
                display: flex;
                margin: auto;
                gap: 10px;
            }
        }
    }
}