@import "src/styles/variables"; 

.appointment-type-details__container {
    .appointment-type-details__wrapper {
        background-color: white;
        padding: 2rem;
        border-radius: 8px;
        .edit-btn__wrapper {
            .ant-btn {
                background-color: $primary-color;
                color: white;
                i::before {
                    color: white;
                    margin-right: 12px;
                }
            }
        }
        .field__wrapper {
            p {
                margin: auto 0;
            }
            .title {
                color: $secondary-black;
            }
            .value {
                margin-top: 10px;
            }
            .value-color {
                height: 20px;
                width: 15vw;
                margin-top: 10px;
            }
        }
    }
}