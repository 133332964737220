@import "src/styles/variables";

.allocated-slot {
    position: relative;
    color: #000;
    width: 100%;
    height: 100%;

    &__more {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__info {
        align-items: center;
        display: flex;
        gap: 5px;

        &__pic {
            width: 30px;
            height: 30px;
            border-radius: 100%;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            gap: 2px;
            align-items: flex-start;

            &__status {
                border-radius: 4px;
                padding: 4px 6px;
                line-height: 1;
            }

            &__doctor {
                font-size: 11px;
                font-style: italic;
                color: #4F4F4F;
            }
        }
    }
}