@import "src/styles/variables";

.patient-document-upload-form {
}

.patient-document-upload-form__upload-wrapper {
  background-color: #ffffff;
  padding: 3rem 4rem;
  border-radius: 8px;
}
