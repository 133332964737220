@import "src/styles/variables";

.history-chart {
    position: relative;
    .patient__detail {
        width: 250px;
        position: absolute;
        right: 0;
        top: 0;
    }
}