@import "src/styles/variables";

.custom-form-response-detail {
}

.custom-form-response-detail__header {
  background-image: linear-gradient(90deg, $primary-color, $secondary-color);
  padding: 1rem 1rem 1rem 2rem;
  border-radius: 4px 4px 0 0;
  h4 {
    color: #ffffff;
    margin: 0;
  }
}

.custom-form-response-detail__body {
  border: 1px solid $secondary-color;
  border-top: none;
  padding: 2rem;
}

.ant-btn.custom-form-response-detail__close-btn.ant-btn-icon-only {
  background-color: $half-white;
}

.custom-form-response-detail__label {
  color: $secondary-black;
  margin-bottom: 10px;
}

.custom-form-response-detail__value {
  color: $primary-black;
}

.custom-form-response-detail__form-overview {
  background-color: $light-grey;
  padding: 1.5rem;
  border-radius: 8px;
}

.custom-form-response-detail__form-response {
  padding: 1.5rem;
  margin-top: 2rem;
}

.custom-form-response-detail__form-item {
  margin-bottom: 10px;
  .custom-form-response-detail__value {
    font-weight: bold;
    margin-left: 12px;
  }
  .custom-form-response-detail__response-item {
    margin-bottom: 10px;

    &__sign {
      width: 100%;
      height: 150px;
      border: 0.2px solid $body-bg--dark;
      border-radius: 6px;
    }
  }
}

.custom-form-response-detail__section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  &__title{
    color: $primary-color;
    font-weight: 600;
  }

  &__content{
    background-color: #6666661A;
    border-radius: 10px;
    padding: 20px;
  }
}