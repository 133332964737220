.dashboard-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  // div {
  //     &:last-child {
  //         display: flex;
  //     }
  // }
}

.dashboard-filter {
  .date__wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 50px #6666660d;
    border-radius: 8px;
    padding: 2.5rem;
  }

  .date-controller {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    .ant-btn.ant-btn-primary {
      margin-left: 8px;
    }
  }
}
