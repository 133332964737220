@import "src/styles/variables";

.select-service {
}

.select-service__form {
  width: 100%;
}
.edit-btn {
  width: 33px;
  cursor: pointer;
}

.select-service__service-header {
  color: $secondary-black;
}

.edit-btn {
  margin-right: 12px;
  i::before {
    color: white;
  }
}

.select-service__service-field {
  padding: 1.5rem 1rem;
  background-color: $table-bg;
  border-radius: 8px;
  margin-bottom: 1rem;
  .dropdown-field,
  .input-field-wrapper {
    margin-bottom: 0;
  }
}
.select-service__overview .title {
  margin-left: -40px !important;
}

.select-service__overview > .select-service__overview-col {
  height: 3rem;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  .input-field-wrapper {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 0;
  }
  &:not(:last-child) {
    border-right: 1px solid $primary-border;
  }
}
