@import "src/styles/variables";

.interaction-visit-details {
}

.interaction-visit-details__field {
	margin-bottom: 10px;
}

.interaction-visit-details__stacked {
	height: 450px !important;
	overflow-y: scroll;
	.interaction-visit-details__field {
		row-gap: 0px !important;
		.interaction-visit-details__label {
			margin: auto;
			font-weight: 100 !important;
			color: #bebebe;
		}
		.interaction-visit-details__value {
			font-weight: bold;
		}
	}
}
#a4d-babylon-scene {
	width: 100% !important;
}

.interaction-visit-details__label {
	margin-bottom: 10px;
	font-family: DMSansBold, sans-serif;
	font-size: 15px;
}

.chart__trigger {
	display: flex;
	justify-content: flex-end;
	.anticon {
		background-color: $secondary-color;
		color: white;
		padding: 10px;
		border-radius: 100%;
		cursor: pointer;
	}
}

.interaction-visit-details__rate-icons {
	margin-right: 5px;
	font-size: 25px;
	cursor: pointer;
	border-radius: 50em;
	position: relative;
	width: 30px;
	height: 30px;
	display: inline-block;
	i {
		position: absolute;
		width: 20px;
		height: 20px;
		font-size: 20px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
	&.active {
		background-color: $secondary-color;
		i {
			color: $primary-white;
		}
	}
}
