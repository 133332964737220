@import "src/styles/variables";

.register-payment-details-form__subscription-item {
  box-shadow: $secondary-shadow;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  ul > li {
    margin-bottom: 0.8rem;
  }

  .payment-detail {
    padding-left: 0px;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      font-weight: lighter !important;
      font-size: 14px;
      button {
        height: 18px;
        width: 18px;
        padding: 0px;
        margin-right: 1.25rem;
        span {
          font-size: 10px;
        }
      }
    }
  }

  .trial-period {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 100%;
    div {
      color: white;
      font-size: 18px;
      border-radius: 24px;
      background-color: $secondary-color;
      text-align: center;
      font-weight: normal !important;
      padding: 6px 28px;
    }
  }
}

.register-payment-details-form__subscription-list {
}


.register-payment-details-form__price {
  // background-color: $primary-color;
  font-weight: bold;
  font-size: 22px;
}

.register-payment-details-form__statistics {
  padding-left: 20px;
}

.register-payment-details-form__add-ons {
  ul {
    margin-top: 10px;
    padding-left: 15px;
  }
}

.register-payment-details-form__active-btn__wrapper {
  position: absolute !important;
  top: 16px;
  right: 16px;
}

.ant-btn.ant-btn-icon-only.register-payment-details-form__active-btn {
  background-color: $primary-color;
  border-radius: 50%;

}

.saving {
  border-radius: 24px;
  padding: 4px 16px;
  background-color: rgba($color: $primary-color, $alpha: 0.2);
  display: inline;
}

.toggle-control {
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  .detail {
    width: 100%;

  
  }
  .day-control {
    margin-top: 12px;
    display: flex;
    background-color: rgba($color: $secondary-black, $alpha: 0.1);
    border-radius: 24px;
    width: 100%;
    div {
      padding: 8px 18px;
      text-align: center;
      width: inherit;
      color: rgba($color: $primary-black, $alpha: 0.7);
      font-size: 16px;
      span {
        font-size: 12px;
        font-weight: lighter !important;
      }
    }

    .subscription-type__selected {
      background-color: $primary-color;
      border-radius: 24px;
      color: white;
    }
  }
}


.register-payment-details-selected {
  border: 1px solid $primary-color !important;
}