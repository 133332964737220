@import "src/styles/variables";

.register-wrapper {
  //margin: -6rem 0 0 -30%;
}

.ant-col-7.register-wrapper__background-wrapper {
  background-image: linear-gradient(180deg, $secondary-color, $primary-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  height: 100vh;
  .register-wrapper__background-content {
    text-align: center;
    h3 {
      margin-top: 5rem;
      color: #ffffff;
    }
  }
}

.register-wrapper__content-wrapper {
  padding: 2rem 4rem;
  margin-left: 29%;
}

.register-wrapper__header {
  position: fixed;
  top: 0;
  left: 30%;
  width: 70%;
  height: 4rem;
  padding: 1.5rem 4rem;
  background-color: $body-bg;
  z-index: 1;

  h4,
  .register-wrapper__stepper {
    display: inline-block;
    vertical-align: middle;
  }

  .register-wrapper__stepper {
    float: right;
  }
}

.register-wrapper__step-item {
  background-color: $light-white;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  &.active {
    background-color: $secondary-color;
  }
}

.register-wrapper__route-wrapper {
  margin-top: 4rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  padding: 2rem 3rem;
}

.register-wrapper__logo {
  margin-top: 10rem;
  width: 40%;
}
