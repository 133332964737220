@import "src/styles/variables";

.contract-form__container {
  .stepper-wrapper {
    display: flex;
    justify-content: flex-end;
    span {
      margin: auto 0;
      margin-right: 15px;
    }
    .dot {
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background-color: $light-white;
      margin: auto 7px;
      transition: background-color 0.5s;
    }
    .dot-active {
      background-color: $secondary-color;
      transition: background-color 0.5s;
    }
  }
}

.contract-form__component-wrapper {
  margin-top: 2rem;
  .title {
    display: flex;
    margin-bottom: 3rem;
    h4 {
      margin: auto 0;
      &:first-child {
        background-color: $secondary-color;
        color: white;
        padding: 0.1rem 0.7rem;
        margin-right: 15px;
      }
    }
  }
}

.contract-form__card {
  padding: 2rem 3rem;
  background-color: #ffffff;
  // box-shadow: $primary-shadow;
  border-radius: 8px;
}
