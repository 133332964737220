@import "src/styles/variables";

.procedure-step-form {
  .dropdown-field {
    margin-bottom: 0;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector {
    padding: 7px 15px;
    border: 1px solid $primary-border;
    height: auto;
    border-radius: 8px;
    background-color: $input-bg--dark;
    color: $primary-white;
  }
  .ant-select-arrow {
    color: $primary-white;
  }
}

.procedure-step-form__form-body {
  margin-bottom: 1rem;
  .dropdown-field {
    margin-bottom: 0;
  }
  .ant-btn:not(.ant-btn-icon-only) {
    padding: 5px 15px;
  }
}

.procedure-step-form__form-body__submit-wrapper {
  margin-top: 1rem;
}
