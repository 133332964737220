@import "src/styles/variables";

.restricted-page {
  display: flex;
  justify-content: center;
  align-items: center;
  .content__wrapper {
    text-align: center;
    img {
      height: 500px;
    }

    p {
      margin-top: 24px;
      font-size: 20px;
      color: $primary-black;
      font-weight: lighter;
    }
  }
}
