.self-payment-detail {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 16px;

    &__left,
    &__right {
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 36px 48px;
    }

    &__left {
        &__fields {
            display: flex;
            flex-direction: column;
            gap: 30px;

            &__item {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &__label {
                    color: #4D4F5C;
                    font-size: 12px;
                }

                &__value {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }

    &__right {
        .ant-table-wrapper {
            background: none;

            .ant-table {
                background: none;

                table {
                    background: none !important;

                    thead,
                    th {
                        background: none !important;
                    }

                    tbody.ant-table-tbody {
                        tr.ant-table-row {
                            background: none !important;
                            border-radius: 0;
                            box-shadow: none;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}