@import "src/styles/variables";

.set-step-form {
  .dropdown-field {
    margin-bottom: 0;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector {
    padding: 7px 15px;
    border: 1px solid $primary-border;
    height: auto;
    border-radius: 8px;
    background-color: $input-bg--dark;
    color: $primary-white;
  }
  .ant-select-arrow {
    color: $primary-white;
  }
  .icon-btn {
    height: 0.9rem;
  }
}

.set-step-form__form-body__submit-wrapper {
  margin-top: 1rem;
}

.treatment-template__set__wrapper {
  button {
    padding: 6px 14px !important;
    width: 105%;
  }
}
