@import "src/styles/variables";

.patient-detail-wrapper {
}

.patient-detail-wrapper__basic-details,
.patient-detail-wrapper__responsible-person {
  box-shadow: $primary-shadow;
  padding: 2rem;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 2rem;
  h5 {
    border-bottom: 1px solid $primary-border;
    padding-bottom: 10px;
    margin-bottom: 2rem;
  }
}
