@import "src/styles/variables";

.attachment-upload {
}

.attachment-upload__input {
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: $light-white;
  text-align: center;
}

.attachment-upload__icon {
  display: block;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.attachment-upload__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.attachment-upload__title {
  margin: 0 0 6px 0;
  color: $secondary-black;
  font-weight: 500;
}
