@import "src/styles/variables";

.treatment-viewer {
	height: 80vh;
	position: relative;

	.babylon-scene {
		#babylon-scene__canvas {
			height: 100%;
		}
	}

	.treatment-viewer__slider__container {
		width: 20%;
		.treatment-viewer__slider {
			width: 100%;
			margin: auto !important;
			.ant-slider-track {
				background-color: $primary-color !important;
			}
			.ant-slider-handle {
				background-color: $primary-color !important;
				border-color: $primary-color !important;
			}
		}
	}

	.treatment-horizontal {
		display: flex;
		position: absolute;
		left: 45px;
		top: 20px;
		img {
			height: 18px;
			&:first-child {
				margin-right: 12px;
			}
			&:last-child {
				margin-left: 12px;
			}
		}
	}

	.treatment-vertical {
		position: absolute;
		top: 50px;
		left: 20px;
		height: 18%;
		.treatment-viewer__slider {
			margin-left: 3px !important;
		}
		img {
			height: 18px;
			&:first-child {
				margin-bottom: 12px;
			}
			&:last-child {
				margin-top: 12px;
			}
		}
	}
}

.ant-popover.set-viewer__popover {
	.ant-popover-content > .ant-popover-arrow {
		display: none;
	}
	.ant-popover-inner-content {
		background-color: $card-bg--dark;
		box-shadow: $primary-shadow;
		width: 350px;
		color: $primary-white;
	}
}