/* Color palette */
$primary-color: #00ae89;
$secondary-color: #1c2f80;
$tertiary-color: #7b0cd0;
$progress-bg: #478f9d;
$progress-text-bg: #198987;
$light-white: #e2e2e2;
$half-white: #ffffff50;
$primary-light-color: #00ae8980;
$secondary-light-color: #1c2f8050;
$primary-black: #2b2b2b;
$secondary-black: #8e8e8e;
$primary-border: #bebebe;
$dark-blue: #22509d;
$link-color: #2b94f0;
$blue-secondary: #0057ae;
$light-grey: #00000015;
$very-light-grey: #787878;
$dark-grey: #8898aa;
$success-color: #00ae89;
$danger-color: #f62b2b;
$warning-color: #f67413;
$gold-color: #e2b854;
$primary-white: #ffffff;
$body-bg: #fefcf7;
$table-bg: #fbfbfb;
$input-bg: #f8fbfc;
$table-head: #777c80;
$card-primary-bg: #ced9fd;
$card-secondary-bg: #f6cba1;
$card-tertiary-bg: #bddba6;
$sidebar-profile-bg: #f0f1f7;
$sidebar-display: block;
$header-display: block;
$app-wrapper-margin: 4rem 0 0 20%;
$app-wrapper-margin--collapsed: 4rem 0 0 8%;
$app-wrapper-width: 80%;
$popover-default-bg: #e4e4e4;

/* Dark mode variables */

$primary-text--dark: #ffffff;
$body-bg--dark: #2b2b2b;
$input-bg--dark: #3a3a3a;
$card-bg--dark: #3a3a3a;
$card-light-bg--dark: #4f4f4f;
$sidebar-display--dark: none;
$header-display--dark: none;
$app-wrapper-margin--dark: 0 0 0 0;
$app-wrapper-width--dark: 100%;
$object-preview-bg: #232322;
$dark-dropdown: #4F4F4F;
$dark-dropdown-color: #8E8E8E;
$dark-dropdown-arrow: #747474;

/* Shadows */
$primary-shadow: 0px 20px 50px #6666660d;
$secondary-shadow: 0px 10px 25px #28388f1a;
$slot-shadow: 0px 2px 10px #6666661f;
/* Spacer variables */
$spacer-x: 1rem;
$spacer-y: 1rem;
