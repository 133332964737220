@import "src/styles/variables";

.clock {
  width: 468px;
  height: 263px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 50px #6666660d;
  border-radius: 8px;
  opacity: 1;
  padding: 2rem;

  .clock__heading {
    display: flex;
    h4 {
      padding-left: 15px;
    }
  }
  .clock__close-btn {
    font-size: 20px;
    cursor: pointer;
  }
  .clock__value {
    font-weight: 900;
  }
  button {
    color: $primary-white !important;
    max-width: 120px;

    img {
      width: 16px;
      height: 18px;
    }
  }
  button:disabled {
    background-color: $secondary-black !important;
    opacity: 0.3;
  }

  .color-primary {
    background-color: $primary-color;
  }
}
