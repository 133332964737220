.appointment-template-date {
  padding: 2rem;
  background-color: #ffff;
  .rbc-btn-group {
    display: none;
  }
  .rbc-toolbar-label {
    display: none;
  }
  .rbc-date-cell {
    text-align: center;
    button {
      outline: none;
      margin-top: 30px;
    }
  }
  .rbc-month-view {
    border: none;
  }
  .rbc-day-bg + .rbc-day-bg,
  .rbc-month-row,
  .rbc-header {
    border: 1px solid #f9f9f9;
  }
  .event-title {
    // margin-top: 20px;
    word-wrap: wrap;
    display: flex;
    border-radius: 12px !important;
    width: 100% !important;
    padding: 5px 8px !important;
    margin: auto;
  }

  .rbc-event {
    word-wrap: wrap;
    display: flex;
    border-radius: 8px;
    width: 90%;
    align-items: center;
    justify-content: center;
    height: 30px;
    background-color: transparent;
    color: black;
    .rbc-event-content {
    }
  }
}
.appointment-template__dropdown {
  .ant-select {
    .ant-select-selector {
      width: 180px !important;
      height: 35px !important;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.5px solid #bebebe;
      border-radius: 8px !important;
    }
  }
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px !important;
  }
}
.date-in-past {
  width: 14.3%;
  background: #f0f0f0;
  border-right: solid 1px #fff;
}
.rbc-row-bg {
  .select {
    background: #cbe0fd;
    border-right: solid 1px #fff;
  }
}
.rbc-selected,
.event-title .ant-popover-open {
  border: transparent !important;
}
.appointment-popover {
  cursor: pointer;
}
.rbc-today{
  background-color: transparent;
}