@import "src/styles/variables";

.contract-detail-terms {
  position: relative;

  .agreement__signature {
    img {
      margin-top: 10px;
      width: 300px;
      border: 0.2px solid black;
      padding: 1rem;
      border-radius: 12px;
    }
  }
  .contract-detail-terms__details {
    padding: 2rem;
    border: 1px solid black;
    max-height: 60vh;
    overflow-y: scroll;
    // background-color: $light-white;
    .arrow {
      width: 18px;
    }

    &__navigator{
      background-color: #fff;
      display: inline-block;
      height: 50px;
      inset-block-start: 1px;
      inset-inline: 2rem;
      padding-block-start: 2rem;
      position: absolute;
      z-index: 10;
    }
  }
}

.contract-detail-terms__template-name {
  font-family: DMSansBold, sans-serif;
}
