@import "src/styles/variables";

.appointment-detail {
  .ant-collapse{
    .ant-collapse-item{
      border-bottom: 0;
    }
  }
  .site-collapse-custom-panel{
    border: 0;
  }
  .ant-collapse-content {
    img {
      width: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .self-care__container {
    .content-container {
      p {
        margin: 0 auto;
      }

      .title {
        color: $card-light-bg--dark;
      }
      .value {
        font-weight: bold;
      }
    }
  }

  .step-note {
    color: $card-light-bg--dark;
  }

  .accordion-padding {
    padding: 10px 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    .content {
      p {
        margin: auto 0;
      }
      .title {
        font-weight: bold;
      }
      .value {
        color: $card-light-bg--dark;
      }
    }

    i::before {
      color: $danger-color;
    }
  }
}
