@import "src/styles/variables";

.custom-form {}

.custom-form-wrapper__basic-details,
.custom-form-wrapper__field-item,
.custom-form-wrapper__sections__item {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: $primary-shadow;
}

.custom-form-wrapper__options {
  margin-top: 2rem;
}