@import "src/styles/variables";

.support-form {
    .content__wrapper {
        background-color: white;
        padding: 1.75rem 2.74rem;
        box-shadow: $primary-shadow;
        border-radius: 8px;

        .patient-applicable {
            margin-top: 0.5rem;
        }
    }
}