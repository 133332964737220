@import "src/styles/variables";

.contract-detail-payment-schedule {
}

.contract-detail-payment-schedule__self-payment {
  background-color: $table-bg;
  padding: 1.5rem;
  border-radius: 8px;
}

.contract-detail-payment-schedule__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.contract-detail-payment-schedule__value {
  font-family: DMSansBold, sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
}

.ant-collapse-borderless.contract-detail-payment-schedule__collapse {
  background-color: transparent;
  & > .ant-collapse-item {
    background-color: $table-bg;
    border: none;
    margin-bottom: 10px;
  }

  .contract-detail-payment-schedule__panel {
    .ant-collapse-content {
      padding: 1rem;
    }
  }
}
