@import "src/styles/variables";

.interaction-sub-status__container {
    .add-sub-status__wrapper {
        color: $secondary-color;
        cursor: pointer;
    }   
}

.sub-status__wrapper {
    width: 60%;
    padding: 5px 0px;
    cursor: pointer;
    .gen-color{ 
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin: 2.5px auto 0px;
    }
    .ellipsis-icon {
        color: #E2E2E2;
        font-size: 34px;
    }
    .delete__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        i {
            color: red;
        }
    }
}
