.custom-event-template {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;


    &__event {
        width: 100%;
        text-align: center;
    }

    &__popover {
        display: flex;
        flex-direction: column;
        gap: 5px;
        cursor: pointer;
    }
}