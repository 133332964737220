.pdf-container{
    &__iframe{
     width: 450px;
    height: 500px;
    }
    
}
.image-container{
img{
    max-width: 600px;
    max-height: 600px;
}
}