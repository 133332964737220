@import "src/styles/variables";

.time-picker-field {
  margin-bottom: 1.5rem;
  .ant-picker {
    border-radius: 8px;
    padding: 10px 12px;
    width: 100%;
    border-color: $primary-border;
    .ant-picker-input > input {
      font-size: 16px;
    }
  }
}

.time-picker-field__title {
  margin: 0 0 6px 0;
  color: $dark-grey;
  font-weight: 500;
}
