@import "../../../../styles/variables";

.appointment-template-wrapper {
    &__header {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        align-items: center;

        &__back {
            &::before {
                align-items: center;
                background-color: #28388F;
                border-radius: 8px;
                color: #fff;
                content: " \003C";
                display: flex;
                height: 32px;
                justify-content: center;
                width: 32px;
            }
        }

        &__heading {
            color: #28388F;
            font-size: 15px;
            font-weight: bold;
            text-transform: capitalize;
        }

        &__time {
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 8px;
            border: 1px solid rgba($primary-border, 0.5);
            color: $primary-black;
            cursor: pointer;
            display: flex;
            font-size: 13px;
            font-weight: normal;
            height: 30px;
            margin-left: 20px;
            min-width: 150px;
            text-align: center;
        }
    }
}