@import "src/styles/variables";

.interaction-detail {
	width: 100%;
	height: calc(100vh - 2rem);

  &__status-toggle {
    min-width: 185px;
    height: 100%;

    &.ant-select {
      margin-right: 15px;

      &:not(.ant-select-disabled){
        &:hover{
          .ant-select-selector{
            border-color: inherit;
            box-shadow: none;
          }
        }
      }
    }

    .ant-select-arrow {
      color: $dark-dropdown-arrow;
    }

    &.ant-select-single:not(.ant-select-customize-input),
    &.ant-select-focussed.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector{
        align-items: center;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid $dark-dropdown;
        box-shadow: none;
        color: $dark-dropdown-color;
        height: 100%;
        padding: 13px 19px;
      }
    }
  }
  .anticon.anticon-delete {
    margin: auto 0;
    color: $danger-color;
    cursor: pointer;
  }

  .treatment-plan__title {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    span {
      font-style: italic;
    }
  }
  &__header {
    .ant-col {
      height: 50px;
  
      .secondary-btn:not(.ant-btn-icon-only) {
        padding: 15px;
      }
  
      .btn-danger:not(.ant-btn-icon-only) {
        height: 100%;
      }
    }
  
    .patient-basic-details {
      &__img-wrapper.patient-basic-details__popover {
        padding: 8px 10px;
  
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  
    .is-medical-emergency {
      display: flex;
      justify-content: center;
    }
  
    .ant-btn {
      border-radius: 4px;
  
      &.ant-btn-icon-only {
        padding: 8px 10px;
        width: 55px;
        height: 50px;
  
        i {
          font-size: 16px;
        }
      }
  
      &.icon-btn {
        margin-left: 0;
      }
    }
    .visit-btn {
      .ant-btn.icon-btn.ant-btn-icon-only.dark-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap:5px;
      }
    }
    .icon-btn {
      &--grp {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
    
    .ant-btn-icon-only {
      &.secondary-btn {
        background-color: lighten($secondary-color, 25%) !important;
      }
    
      .icon-btn__highlight {
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    
        &--danger {
          background-color: darken($danger-color, 10%);
        }
    
        &--secondary {
          background-color: lighten($secondary-color, 10%);
        }
      }
    }
  }
	.ant-btn.ant-btn-icon-only {
		&.dark-btn {
			background-color: $input-bg--dark;
		}
	}

	.visit-btn {
		.ant-btn.icon-btn.ant-btn-icon-only.dark-btn {
			span {
				font-size: 12px !important;
			}
		}
	}
}

.visit-header__container {
	.ant-collapse-content-box {
		padding: 10px 0px !important;
	}
	.visit-header {
		.no {
			// margin: auto 0;
		}

		.secondary-btn {
			padding: 5px 10px !important;
		}
	}
}

.interaction-detail__notes {
  background-color: $card-bg--dark;
  padding: 1rem;
  border-radius: 4px;
}

.interaction-detail__viewer-wrapper {
  margin-top: 1rem;
}

.ant-collapse.interaction-detail__collapse {
  border: none;
  background-color: $card-bg--dark;
  overflow-y: scroll;
  height: 71vh;
  > .ant-collapse-item {
    margin-bottom: 8px;
    > .ant-collapse-header {
      background-color: $card-light-bg--dark;
      color: $primary-white;
      padding-block: 10px;
    }
  }
}

.collapse__header {
  display: flex;

  p {
    margin: auto 0 auto 10px;
  }

	.icon {
		margin: auto 0;
		font-size: 16px;
		cursor: pointer;
	}
	.icon-image__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		.icon-image {
			height: 16px;
		}
	}
	.icon-image {
		height: 16px;
	}
}

.interaction-detail__set-item.ant-collapse {
  background-color: $light-white;
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
  > .ant-collapse-item {
    background-color: $light-white;
    border-radius: 8px;
    &:last-child {
      border-radius: 8px;
      > .ant-collapse-header {
        border-radius: 8px;
      }
    }
    > .ant-collapse-header {
      border: none;
      background-color: $light-white;
      color: $primary-black;
      border-radius: 8px;
      padding: 1rem;
      .ant-collapse-arrow {
        right: 12px;
        top: 8px;
      }
    }
    .ant-collapse-content {
      border: none;
      background-color: $light-white;
    }
    .interaction-detail__set-header-row {
      svg {
        color: $danger-color;
        cursor: pointer;
        margin-top: -1px;
      }
    }
  }
}

.interaction-detail__link-item {
  background-color: $light-white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  svg {
    color: $danger-color;
    cursor: pointer;
  }
}

.interaction-detail__set-step-item {
  background-color: $light-white;
  border-radius: 8px;
  margin-bottom: 5px;
}

.ant-popover.interaction-detail__popover {
  color: #ffffff;
  > .ant-popover-content > .ant-popover-arrow {
    border-top-color: $card-bg--dark;
    border-left-color: $card-bg--dark;
  }

  .ant-popover-inner {
    background-color: $card-bg--dark;
    width: 400px;
    padding: 1rem;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
.visit-btn__popover {
  .ant-popover-arrow {}
  .ant-popover-inner {
    background-color: #E4E4E4;
    padding: 20px 18px;

    .ant-popover-title {
      border: 0;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-collapse-item {
      background-color: #ffffff;
      border-radius: 4px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .ant-collapse-header {
        display: flex;
        align-items: center;

        .visit-header {
          flex-grow: 2;
          display: flex;
          align-items: center;


        }

        .anticon-caret-right {
          font-size: 18px;
        }
      }
    }
  }
    
    .ant-collapse-item-active {
    
      .visit-header,
      .anticon-caret-right {
        color: #2543E6 !important;
      }
    }
    }