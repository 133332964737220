@import "src/styles/variables";

.contract-terms-template-view {
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: $primary-shadow;
    border-radius: 8px;

  .editor {
  }

  .ck-editor__top {
    display: none;
  }

  p {
      margin: 0px;
  }

  .label {
    color: $secondary-black;
    font-size: 15px;
  }

  .ck-editor__main {
    pointer-events: none;
    .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
      background-color: transparent !important;
      border: none !important;
    }
  }
}
