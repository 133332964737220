@import "src/styles/variables";

.corporate-information {
  box-shadow: $primary-shadow;
  padding: 2.75rem;
  background: white;
  border-radius: 8px;
  margin-top: 1rem;
  label {
  }

  p {
    color: $primary-black;
    margin-top: 0.35rem;
    margin-bottom: 1.25rem;
  }

  .link {
    color: $dark-blue;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin-top: 0.35rem;
    margin-bottom: 1.25rem;
  }
}
