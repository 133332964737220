@import "src/styles/variables";

.corporate-object-detail__header {
  margin-bottom: 1rem;
}
.corporate-object-detail__body {
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

.corporate-object-detail__field {
  margin-bottom: 15px;
}

.corporate-object-detail__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.corporate-object-detail__value {
  font-family: DMSansBold, sans-serif;
  font-size: 16px;
}

.corporate-object-detail__color {
  width: 200px;
  height: 30px;
  display: block;
  margin-top: 10px;
  border: 1px solid $primary-border;
  border-radius: 4px;
}
