@import "src/styles/variables";

.appointments__container {

  &__header {
    display: flex;
    font-size: 13px;
    gap: 5px;
    justify-content: space-between;
    margin-bottom: 15px;

    & input,
    .ant-select,
    .ant-picker-input>input {
      font-size: 13px;
    }
    
    .type__wrapper { 
      display: flex;
      gap: 5px;

      button {
        font-size: inherit;
        padding: 7px 20px;
      }
    }
  }

  .type__wrapper {
    display: flex;
    justify-content: flex-end;
    .ant-btn.ant-btn-text {
      background-color: #dddddd;
      color: #4f4f4f;
    }
  }
  .filter-day__Wrapper {
    display: flex;

    .ant-select {
      width: 150px;
      margin-left: 8px;
    }
    .dropdown-field{
      margin-bottom: 0;

      .ant-select{
        height: 100%;
      }
      .ant-select-selector{
        align-items: center;
        padding: 0 12px;
        height: 100%;
      }
    }
  }
  .ant-picker.ant-picker-range.ant-picker-borderless.calender-range-picker {
    border-color: #dddddd !important;
    background: white !important;
    border-radius: 8px;
    input {
      text-align: center;
    }
  }
  .ant-picker-range .ant-picker-active-bar {
    background: $tertiary-color !important;
  }
  .date-picker__wrapper {
    display: flex;
    .date-picker {
      //   width: 100px !important;
      border-color: #dddddd;
      border-radius: 6px;
      margin-right: 6px;
      input {
        text-align: center;
      }
    }
    .date-change__icons {
      border: 1px solid #dddddd;
      border-radius: 6px;
      background-color: white;
      margin-right: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;

      .anticon {
        padding: 10px;
      }
    }
  }
  .appointment-create {
    background-color: $primary-color;
    padding: 1rem;
    display: inline;
    border-radius: 100%;
    position: fixed;
    right: 5vw;
    bottom: 5vh;
    z-index: 1;
    box-shadow: 0px 4px 29px #00000066;
    cursor: pointer;
    span {
      display: inherit;
      font-size: 18px;
      color: white;
    }
  }
}
