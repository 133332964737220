@import "variables";

/* Helpers CSS */

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-family: DMSansBold;
}

.text-primary,
.text-primary:focus,
.text-primary:hover {
  color: $primary-color;
}
.secondary-black {
  color: $secondary-black;
}
.text-yellow {
  color: #d89f44;
}

.text-secondary,
.text-secondary:focus,
.text-secondary:hover {
  color: $secondary-color;
}

.text-light-grey {
  color: $light-grey;
}

.blue-secondary {
  color: $blue-secondary;
}
.text-dark-grey {
  color: $dark-grey;
}

.text-danger {
  color: $danger-color !important;
}

.text-white {
  color: #ffffff !important;
}
.text-warning {
  color: #f67413;
}

.text-capitalize {
  text-transform: capitalize;
}
.text-italic {
  font-style: italic;
}
.text-upper {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.bb-5 {
  border-bottom: ($spacer-y * 1.25) solid $primary-color !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.primary-black {
  color: $primary-black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}

/* Padding */

.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* Margins */

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: ($spacer-y * 0.25) !important;
}

.mt-2 {
  margin-top: ($spacer-y * 0.5) !important;
}

.mt-3 {
  margin-top: ($spacer-y * 0.75) !important;
}

.mt-4 {
  margin-top: ($spacer-y * 1) !important;
}

.mt-5 {
  margin-top: ($spacer-y * 1.25) !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: ($spacer-y * 0.5) !important;
}

.mb-3 {
  margin-bottom: ($spacer-y * 0.75) !important;
}

.mb-4 {
  margin-bottom: ($spacer-y * 1) !important;
}

.mb-5 {
  margin-bottom: ($spacer-y * 1.25) !important;
}

.ml-2 {
  margin-left: ($spacer-x * 0.5) !important;
}

.ml-3 {
  margin-left: ($spacer-x * 0.75) !important;
}

.ml-4 {
  margin-left: ($spacer-x * 1) !important;
}

.ml-5 {
  margin-left: ($spacer-x * 1.25) !important;
}

.mr-1 {
  margin-right: ($spacer-x * 0.25) !important;
}

.mr-2 {
  margin-right: ($spacer-x * 0.5) !important;
}

.mr-3 {
  margin-right: ($spacer-x * 0.75) !important;
}

.mr-4 {
  margin-right: ($spacer-x * 1) !important;
}

.mr-5 {
  margin-right: ($spacer-x * 1.25) !important;
}
.font-size-1 {
  font-size: 1rem !important;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.active,
.completed,
.setup_complete {
  color: #00ae89;
}
.document_signed,
.payment_pending,
.past_due {
  color: #ea8b0a;
}
.closed,
.inactive,
.invalid,
.written_off {
  color: #ed2323;
}
.signature_pending {
  color: #b01bdd;
}
.initiated {
  color: #2371ed;
}
.pointer-none{
  pointer-events: none;
}

.appointment-form__status,
.visit-status__dropdown {
  .ant-select-selection-item {
    text-transform: capitalize;
  }
}