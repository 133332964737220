.resource-form__container {
	.resource-form__wrapper {
		background-color: white;
		box-shadow: 0px 20px 50px #6666660d;
		border-radius: 8px;
		padding: 2rem;
		.ant-select {
			width: 40%;
		}
        input {
			width: 40%;
        }
	}
	.resource-form__control {
        margin-top: 1rem;
		button {
			width: 100%;
		}
	}
}
