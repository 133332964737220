@import "src/styles/variables";

.user-detail__user-card {
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  margin-top: 2rem;
}

img.user-detail__profile-picture {
  width: 100%;
  border-radius: 4px;
}

.user-detail__label {
  color: $secondary-black;
  display: block;
  margin-bottom: 5px;
}

.user-detail__value {
  font-weight: bold;
}
