@import "src/styles/variables";

.comment-notes-form {
    padding: 0.7rem;
    background: $input-bg--dark !important;
    h5 {
        color: $primary-color;
    }
    .controller {
        display: flex;
        justify-content: flex-end;
    }
}