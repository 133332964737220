@import "src/styles/variables";

.patient-form-response-wrapper {
  margin: 1rem 0;
}

.patient-form-response-wrapper__form-tile {
  background-color: $light-grey;
  padding: 1.5rem;
  border-radius: 8px;
  cursor: pointer;
}

.ant-modal.patient-form-response-wrapper__detail-modal {
  .ant-modal-header {
    background-image: linear-gradient(90deg, $primary-color, $secondary-color);
    h4 {
      color: #ffffff;
    }
  }
  .ant-modal-content {
    padding: 1.5rem;
  }
}
