.appointment-type-form__container {
  .appointment-type-form__field__wrapper {
    background-color: white;
    padding: 2rem;
    box-shadow: 0px 20px 50px #6666660d;
    border-radius: 8px;
    .appointment-type-form__textarea {
      .ant-col {
        .input-field-wrapper {
          margin-top: 50px !important;
        }
      }
    }

    .ant-select {
      &-selection-item,
      &-item-option {
        text-transform: capitalize;
      }
    }
  }
  .appointment-type-form__controller {
    margin-top: 2rem;
    .ant-btn {
      width: 100%;
    }
  }

  .treatment-color {
    height: 50px !important;
  }
}
.create-appointment-template__color {
  width: 32px;
  height: 32px !important;
  border-radius: 4px;
  display: inline-block;
  margin-left: 10px;
}
.ant-tag-checkable-checked {
  border: 1px solid black !important;
}
