@import "src/styles/variables";

.work-item__type {
	.work-item__timer {
		color: red !important;
		font-size: 1.1rem !important;
        margin: auto 8px;
	}
	p {
		margin: auto;
        &:first-child {
            margin-bottom: 10px;
        }
        display: flex;
	}
}
.patient-details__table-cell{
    display: flex;
    align-items: center;
    img {
        height: 34px;
        width: 34px;
        margin-right: 6px;
        border-radius: 100%;
    }
    p {
        margin: auto 0;
    }
}


.work-item__description {
    display: flex;
    p {
        background-color: rgba($color: #CBCDFD, $alpha: 1);
        padding: 0.2rem 0.5rem;
        margin-right: 8px;
        border-radius: 8px;
        &:first-child {
            background-color: rgba($color: #CBFDCB, $alpha: 1);
        }
    }
}

.work-item__flag {
    color: $danger-color;
    font-size: 1.3rem;
    margin-left: 8px;
}