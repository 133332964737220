@import "src/styles/variables";

.service-wrapper {
}
.service-tabs {
  .create-btn {
    float: right;
  }
  .ant-tabs.ant-tabs-top.ant-tabs-small {
    width: 100%;
  }
}
