@import "src/styles/variables";

.user-form {
  padding: 2rem;
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  border-radius: 8px;
  .ant-input-group-addon {
    width: 75px;
    padding: 0;
    .dropdown-field {
      margin: 0;
    }
  }
}
