@import "src/styles/variables";

.appointment-template {
  .ant-col {
  }
  .template-color {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
}
.create-appointment-template {
  padding: 32px;
  background-color: #ffff;
  height: 500px;
  
  &__color-label {
    margin-bottom: 10px;
    color: $secondary-black
  }
  
  .create-appointment-template__color {
    width: 32px;
    height: 32px !important;
    border-radius: 4px;
    display: inline-block;
  }
  .ant-tag-checkable-checked {
    border: 1px solid black;
  }
}
