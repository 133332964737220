@import "src/styles/variables";

.treatment-summary-comment-notes {
}

.treatment-summary-comment-notes__item {
  padding: 1rem;
  box-shadow: $primary-shadow;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 1rem;
}
