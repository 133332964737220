@import "src/styles/variables";

.global-note-template-form__body {
  background-color: $primary-white;
  padding: 2rem;
  box-shadow: $primary-shadow;
  border-radius: 8px;

  .ant-radio-wrapper {
    display: block;
    margin-bottom: 1rem;
    font-size: 16px;
  }

  .global-note-template-form__banner {
    width: 100%;
  }

  .global-note-template-form__note-template-fields {
    margin-top: 4rem;
  }
}

.global-note-template-form__footer {
  margin-top: 1rem;
  text-align: right;
}
