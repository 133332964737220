@import "src/styles/variables";

.service-detail {
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: $primary-shadow;
    border-radius: 8px;

    p {
        margin: 0px;
    }

    .title {
        color: $secondary-black;
        font-size: 14px;
    }

    .value {
        margin-bottom: 1.5rem;
        font-size: 15px;
    }
}