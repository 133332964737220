.babylon-scene {
    position: relative;
    height: 100%;

    &__loader {
        background-color: rgba($color: #fff, $alpha: 0.2);
        position: absolute;
        inset: 0;
    }

    #babylon-scene__canvas {
        width: 100%;
        height: 500px;

        &:focus,
        &:focus-visible {
            outline: none;
        }
    }
}