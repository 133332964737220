@import "src/styles/variables";

.procedure-form {
	.ant-popover-inner {
        width: 500px !important;
        .dropdown-field {
			margin: auto !important;
		}
		button {
			margin: auto !important;
		}
	}
}
