@import "src/styles/variables";

.create-appointment {
    border-radius: 12px;
    padding: 2.5rem;
    background-color: white;
    box-shadow: 0px 2px 20px #5858580d;

    .date-toggle {
        .un-selected {

        }

        .selected {
            
        }
    }
}