.stats__container {
  background-color: white;
  padding: 1.5rem;
  box-shadow: 0px 4px 20px #5858580d;
  border-radius: 8px;
  margin-top: 2rem;
  .stat__container {
    padding: 1.4rem 1rem;
    border-radius: 8px;
    position: relative;
    height: 100%;
    .float-icon {
      position: absolute;
      font-size: 3rem;
      top: 10px;
      right: 10px;
    }
    .stat-icon__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin: auto;
        font-size: 2.2rem;
        padding: 1rem;
        border-radius: 100%;
      }
    }
    .stat-content__wrapper {
      p {
        margin: auto 0;
        font-weight: lighter;
        font-size: 14px;
        height: auto 0;
      }
      h2 {
        margin: auto 10px;
        margin-bottom: 2px;
      }
      a {
        margin-left: 60px !important;
      }
    }
  }
}
