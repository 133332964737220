@import "src/styles/variables";

.treatment-summary-overview {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: $primary-shadow;

  .interaction-viewer {
    margin-top: 0px !important;
  }
}

.treatment-summary-overview__field {
  margin-bottom: 1rem;
}

.treatment-summary-overview__label {
  color: $secondary-black;
}

.treatment-summary-overview__value {
  color: $primary-black;
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
}

.treatment-summary-overview__rate-icons {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  i {
    color: $secondary-black;
    font-size: 20px;
  }
  &.active {
    i {
      background-color: $gold-color;
      border-radius: 50em;
      color: #ffffff;
      font-size: 24px;
    }
  }
}
