.appointment-type__container {
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  padding: 1.5rem;
  table {
    background-color: white !important;
    thead,
    tbody {
      background-color: white;
    }
    th {
      background-color: white !important;
    }
    thead {
      tr {
        background-color: white !important;
      }
    }
    tbody {
      tr {
        box-shadow: none !important;
        background-color: #fbfbfb !important;
        border-radius: 8px;
      }
    }
  }

  .treatment-code {
    height: 15px;
    width: 50px;
    border-radius: 4px;
  }
  .ant-pagenation {
    background-color: white;
  }
  .add-appointment-type__wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
.appointment-type__details {
  .ant-picker {
    width: 155px;
    height: 40px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #bebebe;
    border-radius: 8px;
    opacity: 1;
  }
  .appointment-type__icon {
    img {
      cursor: pointer;
      width: 34px;
      height: 34px;
    }
  }
}
.treatment-color {
  .ant-input {
    height: 46px !important;
    width: 98%;
    border-radius: 8px !important;
  }
}
