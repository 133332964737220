@import "src/styles/variables";

.payment-settings {
  background-color: white;
  border-radius: 8px;
  padding: 1.75rem 2.75rem;
  box-shadow: $primary-shadow;

  h2 {
    font-size: 24px;
    margin-bottom: 2px;
  }

  p {
    color: rgba($color: $primary-black, $alpha: 0.6);
    font-size: 16px;
    margin-bottom: 1.5rem;
  }

  .terms {
    font-weight: bolder !important;
    font-size: 16px;
    margin-top: 2rem;
  }

  .link {
    align-items: center;
    box-shadow: $primary-shadow;
    padding: 0.5rem 1.5rem;
    width: 45%;
    cursor: pointer;
    h4 {
      margin-bottom: 0px;
    }

    p {
      font-weight: lighter !important;
      color: rgba($color: $primary-black, $alpha: 0.4);
    }
  }

  .stripe-id {
    color: rgba($color: $primary-black, $alpha: 0.6);

    span {
    }
  }

  .complete-profile {
    span {
      text-decoration: underline;
      color: #11409A;
      cursor: pointer;
    }
  }
}
