@import "src/styles/variables";

.payment-form {
  label {
    color: $secondary-black;
  }
  .search-autocomplete {
    margin-top: 0.8rem;
    width: 100% !important;
    border: 1px solid #bebebe;
    .ant-input-search {
      width: 100% !important;
    }
  }
}
