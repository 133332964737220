@import "src/styles/variables";

.appointment-form__container {
  .appointment-form__wrapper {
    box-shadow: 0px 20px 50px #6666660d;
    background-color: white;
    border-radius: 8px;
    padding: 2.5rem;

    .appointment-form__controller {
      display: flex;
      justify-content: flex-end;
      button {
        &:last-child {
          margin-left: 8px;
        }
      }
    }
    .visit-type__wrapper {
      .radio-input-field {
        margin-bottom: 0px;
        height: 60% !important;
        .ant-radio-group.ant-radio-group-outline {
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
    }
    .ant-col {
      .appointment-form__custom-dropdown{
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label{
          display: flex;
          justify-content: space-between;

          &__title{
            color: $secondary-black;
            font-weight: bold;
          }

          &__link{
            cursor: pointer;
            font-size: 12px;
          }
        }
        .dropdown-field{
          margin:0;
        }
      }
      .appointment-form__session {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        &__title{
          margin-bottom: 0;
          font-size: 14px;
        }
        &__link{
          @extend .appointment-form__custom-dropdown__label__link;
        }
      }
      .ant-tag {
        padding: 6px 16px !important;
        border-radius: 5px;
        font-size: 14px;
        margin: 10px 0;
      }
      .ant-tag-checkable {
        border: 1px solid #e7e7ed !important;
      }
      .ant-tabs-nav-list {
        padding: 0 !important;
      }
      .ant-tabs-tab {
        background: #f7f7f8;
      }
      .ant-tabs-tab-active {
        background-color: #2c65f0 !important;
        .ant-tabs-tab-btn {
          color: #ffff !important;
        }
      }
    }
  }
}
